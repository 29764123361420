@use 'volumeModule';

.list_wrapper {
    width: 100%;
    row-gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .list_empty {
        color: white;
        font-weight: bold;
        font-size: 1.3rem;
        margin-top: 1.5rem;
    }

    @media only screen and (max-width: volumeModule.$sm) {
        row-gap: 0;
    }
}