@use 'GSHmodule';
@use "sass:math";

@mixin gridContainer($itemAmount) {
    display: grid;
    grid-template-columns: repeat($itemAmount, calc(math.div(100%, $itemAmount) - 0.5rem));
    column-gap: 0.5rem;
    row-gap: 0.7rem;
    justify-content: center;
}

.GOSHold_header {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    margin: 0 0 1rem;

    .header_span {
        color: #01fe72;
    }
}

.GOSHold_container {
    @include gridContainer($itemAmount: 5);

    @media screen and (max-width: GSHmodule.$lg) {
        @include gridContainer($itemAmount: 4);
    }

    @media screen and (max-width: GSHmodule.$md) {
        @include gridContainer($itemAmount: 3);
    }

    @media screen and (max-width: GSHmodule.$sm) {
        @include gridContainer($itemAmount: 2);
    }

    @media screen and (max-width: GSHmodule.$xs) {
        @include gridContainer($itemAmount: 1);
    }
}

.GOSHold_message {
    width: 100%;
    padding-top: 1rem;

    .GOSHold_paragraph {
        color: white;
        font-weight: bold;
        font-size: 1.3rem;
    }
}