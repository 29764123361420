.series4Nft {
    .bodyContent {
        background: rgb(220,238,232);
        background: linear-gradient(135deg, rgba(220,238,232,1) 0%, rgba(82,104,148,1) 100%);
    }

    .ma__rightMenu {
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        }
    .ma__rightMenuItem {
        color: #000000;
        text-decoration: none;
    }
    .ma__rightMenuItem:hover {
        text-decoration: underline;
    }
    .ma__rightMenuItem span {
        padding: 0 20px;
        color: inherit;
        font-weight: 500;
        font-size: 16px;
    }
    .ma__rightMenuItem:last-child span {
        padding-right: 0px;
    }
    .ma__rightMenuItem:not(:last-child):after {
        color: #000000;
        content: "|";
    }
    .alphabet_ma__wrapper {
        font-size: 0px;
    }
    .alphabet_ma__content {
        border-radius: 0px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        background-color: #fff;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: clamp(10px, 1.5vw, 20px);
        font-size: clamp(12px, 1.1vw, 16px);
    }
    .alphabet_maContent__header {
        max-width: 100%;
    }
    .alphabet_ma__contentText {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .alphabet_ma__contentText p {
        margin: 0;
        padding-top: clamp(5px, 1vw, 15px);
        padding-bottom: clamp(5px, 1vw, 15px);
        font-size: clamp(12px, 1.1vw, 16px);
    }

    .alphabet_ma__top {
        display: flex;
        justify-content: space-between;
        margin: 0px;
        background-color: #23417a;
        padding: clamp(10px, 1.5vw, 20px) clamp(10px, 3vw, 41px);
    }
    .alphabet_ma__top:after {
        content: none;
    }
    .alphabet_maMenu__link {
        border-width: 3px;
        border-style: solid;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        max-width: 124px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #000000;
        font-weight: bold;
        font-size: clamp(9px, 1vw, 12px);
        flex: 1;
        margin-right: 1px;
        text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
        box-shadow: 0px 0px 0px 1px inset #000000;
        opacity: 1;
    }
    .alphabet_maMenu__link-yellow {
        border-color: #ffbe00;
        background-color: #ffbe00;
    }
    .alphabet_maMenu__link-red {
        border-color: #fd4848;
        background-color: #fd4848;
    }
    .alphabet_maMenu__link-green {
        border-color: #1cff01;
        background-color: #1cff01;
    }
    .alphabet_maMenu__link-purple {
        border-color: #ff53eb;
        background-color: #ff53eb;
    }
    .alphabet_maMenu__link-blue {
        border-color: #06c7f1;
        background-color: #06c7f1;
    }
    .alphabet_maMenu__link:hover,
    .alphabet_maMenu__link-active {
        background-color: #fff;
    }
    .maTop__right {
        flex: 1;
    }
    .maMenu2__link {
        color: #fff;
        padding: 0px 20px;
        margin-right: 0px;
    }

    .maMenu2__link:not(:last-child) {
        border-right: 1px solid rgba(255,255,255, 0.5);
    }

    .alphabet_ma__main {
        background-color: #8fa4bb;
        padding: 0;
    }
    .alphabet_ma__mainWrap {
        padding: 0 clamp(10px, 1.6vw, 24px);
    }
    .maMain__menuWrap {
        margin-top: 0px;
    }
    .alphabet_ma__collection {
        margin-top: 0px;
        padding-top: clamp(15px, 2vw, 30px);
        padding-bottom: clamp(10px, 1.6vw, 24px);
    }

    .maCollectionContent__link {
        width: 175px;
        height: 270px;
    }

    .maCollection__content {
        grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
        grid-gap: 0;
    }

    .maCollectionContent__link .maMainMenuLink__number {
        bottom: 17.4%;
        right: 12%;
        left: initial;
        width: auto;
        color: #fff;
        text-shadow: 0px 4px 6px rgba(0, 0, 0, 1);
        font-size: clamp(10px, 1vw, 14px);
        font-weight: 400;
    }
    .maCollectionContent__link .maMainMenuLink__number-red {
        font-weight: bold;
        color: #fff;
    }

    // .ma__informer {
    //     background-color: #23417a;
    //     max-width: initial;
    //     border-radius: 0;
    //     color: #fff;
    //     border: none;
    // }
    .ma__bottomColor {
        padding-bottom: 50px;
        padding-top: 50px;
    }
    .maBottomColor__elem {
        text-decoration: none;
    }
    .maMainMenuLinkHover__arrow {
        background-color: transparent!important;
    }

    .maMainMenuLinkHover__text {
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
        font-size: clamp(10px, 1.1vw, 16px);
    }
    .maMainMenuLinkHover__arrow {
        width: 42%;
        height: auto;
    }
    .maMainMenuLink__hover {
        background-color: rgb(30 30 30 / 90%);
    }
    .maMainMenuLink__hover:hover {
        background-color: rgb(30 30 30 / 100%);
        box-shadow: 0 0 5px rgb(30 30 30 / 100%);
    }
    .maCollectionContent__link .maMainMenuLink__hover {
        width: 101px;
        height: 101px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .maCollectionContentLink__bg {
        filter: grayscale(0.85);
    }
    .maCollectionContent__link-active .maCollectionContentLink__bg {
        filter: grayscale(0);
    }

    .maMainMenuLink__buy {
        opacity: 0;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        top: 45%;
        transition: 500ms all;
        opacity: 0;
        z-index: 1;
        width: clamp(100px, 11.8vw, 120px);
        height: clamp(100px, 11.8vw, 120px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        flex-direction: column;
        background-color: rgba(210, 10, 23, 0.9);
    }
    .maMainMenuLink__buy:hover {
        background-color: rgba(210, 10, 23, 1);
        box-shadow: 0 0 5px rgba(255, 255, 255, 1);
    }
    .maMainMenuLink__buy-small {
        top: initial;
        bottom: 17%;
        width: clamp(65px, 8.5vw, 87px);
        height: clamp(65px, 8.5vw, 87px);
    }

    .maCollectionContent__link .maMainMenuLink__hover {
        width: clamp(65px, 8.5vw, 87px);
        height: clamp(65px, 8.5vw, 87px);
    }
    .maCollectionContent__link:hover .maMainMenuLink__buy {
        opacity: 1;
    }

    .maMainMenuLinkBuy__text span {
        text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.5);
        color: #fff;
        text-transform: uppercase;
        line-height: 90%;
    }
    .maMainMenuLinkBuy__text span b {
        font-size: clamp(15px, 2vw, 30px);
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
    }

    body.it .maMainMenuLinkBuy__text span b{
        font-size: 18px;
    }

    body.es .maMainMenuLinkBuy__text span b{
        font-size: 21px;
    }

    .maMainMenuLink__buy-small .maMainMenuLinkBuy__text span b {
        font-size: clamp(12px, 1.5vw, 20px);
        display: block;
    }

    body.es .maMainMenuLink__buy-small .maMainMenuLinkBuy__text span b {
        font-size: 17px;
    }

    body.it .maMainMenuLink__buy-small .maMainMenuLinkBuy__text span b {
        font-size: 15px;
    }

    .maMainMenuLink__buy-small .maMainMenuLinkBuy__text span {
        font-size: clamp(10px, 1vw, 12px);
        font-weight: 500;
    }
    .alphabet_ma__collectionPage {
        margin-top: 0px;
    }
    .collectionWrap {
        margin-top: 0px;
    }
    .maDetail__top {
        margin-bottom: 0px;
    }

    .collectionRow {
        display: flex;
        align-items: center;
        height: 56px;
        justify-content: space-between;
        background-color: #e3e3e3;
        padding: 1px clamp(20px, 3vw, 40px);
    }

    .collectionRow__number {
        max-width: 76px;
        width: 100%;
        text-align: right;
    }

    .collectionRow__icon {
        max-width: 50px;
        width: 100%;
        margin: 0 30px;
    }

    .collectionRow__title {
        font-size: 12px;
        text-decoration: underline;
        font-weight: bold;
        text-align: left;
        flex: 1;
    }

    @media(max-width: 620px) {
        .collectionRow {
            flex-direction: column;
            height: initial;
            padding: 20px 10px;
        }

        .collectionRow__number {
            text-align: center;
        }

        .collectionRow__icon {
            margin: 10px;
        }

        .collectionRow__title {
            text-align: center;
            line-height: 150%;
            padding: 0 20px;
        }

        .collectionRow__button {
            margin-top: 20px;
        }
    }
    .collectionRow__button {
        max-width: 186px;
        width: 100%;
    }

    .collectionBttn {
        cursor: pointer;
        border: 3px solid #ffbe00;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #000000;
        font-weight: bold;
        font-size: 12px;
        margin-right: 1px;
        text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
        box-shadow: 0px 0px 0px 1px inset #000000;
        opacity: 1;
        background-color: #ffbe00;  
        transition: 500ms all;     
    }
    .collectionBttn:hover {
        background-color: #fff;
    }

    .collectionBttn-active,
    .collectionBttn-active:hover {
        border-color: #6eff00;
        background-color: #6eff00;
        box-shadow: 0px 0px 0px 1px inset #fff;
        cursor: no-drop;
    }

    .collectionBttn2 {
        cursor: pointer;
        border: 3px solid #ffbe00;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #000000;
        font-weight: bold;
        font-size: 12px;
        margin-right: 1px;
        text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
        box-shadow: 0px 0px 0px 1px inset #000000;
        opacity: 1;
        background-color: #ffbe00;  
        transition: 500ms all;     
    }
    .collectionBttn2:hover {
        background-color: #fff;
    }

    .collectionBttn2-active,
    .collectionBttn2-active:hover {
        border-color: #ffbe00;
        background-color: #ffbe00;
        box-shadow: 0px 0px 0px 1px inset #fff;
        cursor: no-drop;
    }


    .buyMoreBttn__wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 20px;
    }

    .buyMoreBttn {
        cursor: pointer;
        border: 3px solid #db0314;
        max-width: 186px;
        width: 100%;
        height: 48px;
        display: flex;
        text-decoration: none;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 0px 0px 1px inset #ffffff;
        background-color: #db0314;  
        transition: 500ms all;     
    }
    .buyMoreBttn:hover {
        background-color: #8d0f19;
        border-color: #8d0f19;
    }
    .buyMoreBttn__icon {
        max-width: 26px;
        margin: 0px 10px;
    }
    .buyMoreBttn__icon img {
        width: 100%;
    }

    .buyMoreBttn__label {
        flex: 1;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        color: #fff;
        text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.5);
        text-align: center;
        text-transform: uppercase;
    }

    .maDetailContentMain__left {
        width: auto;
        margin-right: -10px;
        position: relative;
        z-index: 2;
    }

    .alphabet_maTop__left {
        flex: 2;
        position: relative;
        z-index: 1;
    }
    .maMenu2 {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .maMenu2__link {
        max-width: 140px;
        width: 100%;
    }

    #alphabet_content2 {
        margin-top: 50px;
    }

    .alphabetSuccess {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: clamp(20px, 3.5vw, 50px) clamp(10px, 1.8vw, 25px);
        border: 1px solid #8fa4bb;
    }
    .alphabetSuccess__img {
        flex: 1;

    }
    .alphabetSuccess__text {
        padding: clamp(20px, 3.5vw, 50px);
        flex: 1;
    }
    .alphabetSuccess__h2 {
        font-size: clamp(14px, 1.4vw, 20px);
        font-weight: bold;
        color: #b71f25;
        text-transform: uppercase;
        margin: 0;
        padding: 0 0 20px;
    }
    .alphabetSuccess__p {
        color: #000000;
        font-size: clamp(10px, 1.2vw, 16px);
        margin: 0;
        padding: 0 0 20px;
    }


    .alphabetSuccess__button {
        background-color: #ffce54;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        height: 46px;
        border: 1px solid #242b41;
        border-radius: 23px;
        margin-top: 40px;
    }
    .alphabetSuccess__icon {
        padding-right: 25px;
    }
    .alphabetSuccess__label {
        color: #242b41;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .maMenu2__link:not(:last-child) {
        border-right: 1px solid rgb(45 20 84 / 40%);
    }
    .maMenu2__link:last-child {
        border-right: none;
    }

    .maBottomColor__text {
        color: #28242c;
        font-size: 12px;
        opacity: 0.5;
        transition: 500ms all;
    }

    .alphabet_maMenu {
        display: flex;
    }

    @media(max-width: 768px) {
        .alphabet_ma__top {
            flex-direction: column;
        }

        .alphabet_ma__collection:after,
        .alphabet_maTop__left:after {
            display: none;
        }
        .alphabet_maMenu {
            margin: 0 0 25px;
            width: 100%;
        }
    }
    @media(min-width: 769px) {
        .maMainMenuLink__buy-small {
            bottom: 9%;
        }
        // .alphabet_maTop__left {
        //     flex-basis: 625px;
        // }
        .maTop__right {
            flex-basis: 360px;
        }
        .maCollectionContent__link {
            width: clamp(101px, 17.1vw, 175px);
            height: clamp(158px, 27vw, 270px);
        }
        .maMainMenuLink__buy {
            width: clamp(100px, 11.8vw, 120px);
            height: clamp(100px, 11.8vw, 120px);    
        }
        .maCollectionContent__link .maMainMenuLink__hover,
        .maMainMenuLink__buy-small {
            width: clamp(65px, 8.5vw, 87px);
            height: clamp(65px, 8.5vw, 87px);
        }
    }

    @media(min-width: 1024px) {
        .maCollectionContent__link {
            width: clamp(101px, 13.4vw, 202px);
            height: clamp(158px, 21vw, 316px);
        }
        .maMainMenuLink__buy {
            width: clamp(100px, 9.3vw, 138px);
            height: clamp(100px, 9.3vw, 138px);    
        }

        .maCollectionContent__link .maMainMenuLink__hover,
        .maMainMenuLink__buy-small {
            width: clamp(70px, 6.8vw, 101px);
            height: clamp(70px, 6.8vw, 101px);
        }
    }

    @media (max-width: 1024px) {
        .alphabet_maTop__left {
            margin-top: 0;
        }

        .alphabet_maMenu__link {
            width: 200px;
        }
    }

    @media (pointer:coarse) {
        .maCollectionContent__link .maMainMenuLink__hover {
            display: none;
        }
        .maCollectionContent__link .maMainMenuLink__buy {
            display: none;
        }

        .maCollectionContentLink__openLink {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        
        .maCollectionContentLink__buyLink {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    @media (max-width: 576px) {
        .alphabet_maMenu {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 0;
            gap: 0 !important;
        }
    }
}
