@use 'GSHmodule';

.button {
    background: #00000066;
    color: #faff64;
    border: none;
    border-radius: 1rem;
    font-size: 0.9rem;
    box-sizing: border-box;
    padding: 0.3rem 1rem;
    font-weight: 600;
    cursor: pointer;

    @media screen and (max-width: GSHmodule.$lg) {
        font-size: 0.9rem;
    }

    @media screen and (max-width: GSHmodule.$sm) {
        font-size: 1rem;
    }

    @media screen and (max-width: GSHmodule.$xs) {
        font-size: 1.3rem;
    }

    @media screen and (max-width: GSHmodule.$xxs) {
        font-size: 1.1rem;
    }
}