@use '@styles/variables.scss';

.coloredWrapper {
    background: variables.$colorNoGradient;
    background: variables.$colorGradient;    
    min-height: 100vh;
}

.defaultWrapper{
    background-color: #fff;
    min-height: 100vh;
    color: variables.$colorBlack;

    &.wrapper-DAO{
        background-image: -webkit-linear-gradient(123deg, rgb(168, 21, 27) 0%, rgb(21, 39, 62) 100%);
        color: variables.$colorWhite;
    }

    &.wrapper-ROYALTY{
        background-image: -webkit-linear-gradient(123deg, rgb(28, 112, 142) 0%, rgb(52, 7, 86) 100%);
        color: variables.$colorWhite;
    }
    
    &.wrapper-AMBASSADOR{
        background-image: -webkit-linear-gradient(123deg, rgb(28, 105, 193) 0%, rgb(48, 0, 45) 100%);
        color: variables.$colorWhite;
    }
    
    &.wrapper-METAVERSE{
        background-image: -webkit-linear-gradient(123deg, rgb(28, 105, 193) 0%, rgb(48, 0, 45) 100%);        
        color: variables.$colorWhite;
    }
}

.container {
    max-width: 1200px;
    width: calc(100% - 50px);
    margin: 0 auto;
}

.convertDao {
    background-color: var(--main-abyssal-blue);
    border-radius: 6px;
    padding: 50px;
    position: relative;
    margin-top: 2rem;

    .daoConvertElemRow__amount {
        padding: 0 10px;
        border: none;
    }

    .daoConvertElemRow__amount:focus {
        outline: none;
    }

}

.convertDao__lvl {
    display: flex;
    gap: 30px;
}

.convertDao__lvl-elem {
    flex: 1;
    padding: 30px;
    filter: grayscale(1);
    opacity: 0.4;
    border: 1px solid #fff;
}

.convertDaoLvlElem__wrap {

}

.convertDaoLvl__value {
    font-size: 60px;
    text-align: center;
}

.convertDaoLvl__title {

}

.convertDao__lvl-elem.level-active {
    filter: initial;
    opacity: 1;
    border: 1px solid #005281;
}

.errorMsg {
    color: #c90000;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    margin-top: 5px;
}

@media(max-width: 1024px) {
    .convertDao {
        padding: 20px;
    }

    .convertDaoLvl__value {
        font-size: 40px;
    }

    .convertDao__lvl-elem {
        padding: 20px;
    }

    .daoConvertElemRow__amount {
        box-sizing: border-box;
    }

    .errorMsg {
        font-size: 10px;
    }

}

@media(max-width: 768px) {
    .convertDao__lvl {
        gap: 10px;
    }

    .daoConvert {
        .gico-balance {
            font-size: 16px;
        }
    }
}

@media(max-width: 576px) {
    .convertDaoLvl__value {
        font-size: 30px;
    }

    .convertDaoLvl__title {
        font-size: 13px;
    }

    .convertDao__lvl {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .convertDao__lvl-elem {
        width: 100%;
        box-sizing: border-box;
        margin: 0!important;
    }

    .daoConvert {
        .gico-balance {
            font-size: 14px;
            text-align: center;
        }
    }
}
