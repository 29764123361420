@use 'volumeModule';

.switch_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: row;
    justify-items: center;
    gap: 1rem 1.5rem;
    font-family: 'Gilroy';

    @media only screen and (max-width: volumeModule.$lg) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (max-width: volumeModule.$md) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: volumeModule.$sm) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: volumeModule.$xxs) {
        grid-template-columns: repeat(1, 0.9fr);
        justify-content: center;
    }

    .switch_link {
        all: unset;
        width: 100%;
        cursor: pointer;
        padding: 1rem;
        opacity: 1;
        font-weight: bold;
        font-size: 1.3rem;
        box-sizing: border-box;
        text-align: center;
        border-radius: 1.5rem;
        letter-spacing: 0.05rem;
        word-spacing: 0.1rem;
        box-shadow: 0.3rem 0.3rem 1rem volumeModule.$gray inset;
        text-shadow: 0.05rem 0.05rem 0.2rem volumeModule.$gray;
        opacity: 0.6;
        transition: all 0.25s ease-out;
        overflow: hidden;
        line-height: 1.4rem;

        @media only screen and (max-width: volumeModule.$sm) {
            font-size: 1.2rem;
            line-height: 1.3rem;
        }

        @media only screen and (max-width: volumeModule.$xs) {
            font-size: 1.1rem;
            line-height: 1.2rem;
        }

        @media only screen and (max-width: volumeModule.$xxs) {
            font-size: 1rem;
            line-height: 1.1rem;
        }

        .link_text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .switch_link.active {
        opacity: 1;
    }

    @media (hover: hover) {
        .switch_link:hover {
            box-shadow: -0.1rem -0.1rem 1rem volumeModule.$gray inset;
            text-shadow: 0.05rem 0.05rem 0.3rem volumeModule.$gray;
            transform: scale(1.1);
            opacity: 1;
        }
    }
}