.series5Nft {
    max-width: 1005px;
    width: 100%;
    margin: 0 auto;
}
.header__menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.transformatorMenu__link {
    max-width: 168px;
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #8c96aa;
    font-size: 12px;
    font-weight: bold;
    font-family: 'GothamPro';
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
    border-radius: 23px;
    transition: 500ms all;
    text-decoration: none;
}

.transformatorMenu__link-yellow {
    border: 1px solid #ffce54;
}

.transformatorMenu__link-yellow .transformatorMenu__link-cyrcle {
    background-color: #ffce54;
}

// .transformatorMenu__link-active {}

.transformatorMenu__link-cyrcle {
    position: absolute;
    top: 50%;
    left: 20px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #8c96aa;
    transform: translate(0, -50%);
}

.transformatorMenu__link-red {
    border: 1px solid #ed5564;
}

.transformatorMenu__link-red .transformatorMenu__link-cyrcle {
    background-color: #ed5564;
}

.transformatorMenu__link-green {
    border: 1px solid #a0d568;
}

.transformatorMenu__link-green .transformatorMenu__link-cyrcle {
    background-color: #a0d568;
}

.transformatorMenu__link-purple {
    border: 1px solid #ac92eb;
}

.transformatorMenu__link-purple .transformatorMenu__link-cyrcle {
    background-color: #ac92eb;
}

.transformatorMenu__link-blue {
    border: 1px solid #4fc1e8;
}

.transformatorMenu__link-blue .transformatorMenu__link-cyrcle {
    background-color: #4fc1e8;
}

.transformatorMenu__link:hover {
    background-color: #373c52;
}

.transformatorMenu__link-active {
    background-color: #373c52;
}

.transformator__main {
    margin-top: 70px;
}

.mainTransformatorCards {
    margin-top: 65px;
    display: flex;
    flex-wrap: wrap;
    /* gap: 0px 20px; */
    gap: 0px 7px;
    justify-content: center;
}

.transformatorCard {
    max-width: 184px;
    width: 100%;
    /* height: 235px; */
    position: relative;
    transition: .35s;
    overflow: hidden;
}

.mainTransformatorCards:hover> :not(:hover) {
    opacity: 0.4;
    transform: scale(0.95);
}

.transformatorCard__imgWrap {
    width: 100%;
    /* height: 100%; */
    background-position: center !important;
    background-repeat: no-repeat !important;
    height: 235px;
}

.transformatorCard__count {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 47px;
    left: 50%;
    transform: translate(-50%, 0);
}

.transformatorCardCount__availabel {
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    // margin-right: 5px;
    font-family: 'GothamPro';
}

.transformatorCardCount__all {
    color: #8c96aa;
    font-size: 16px;
    font-family: 'GothamPro';
}

.transformatorCard__open {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    display: block;
    /* position: absolute; */
    /* bottom: -20px; */
    /* right: 0px; */
    font-family: 'GothamPro';
    margin-right: 5px;
    transition: 500ms all;
    transform: translate(120px, 0);
}

.transformatorCardCyrcle {
    width: 110px;
    height: 110px;
    background-color: #000000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 0px 0px #323543;
    color: #ffffff;
    font-size: 26px;
    font-weight: bold;
}

.transformatorCard-empty {
    display: flex;
    align-items: center;
    justify-content: center;
}

.moreTokens {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.moreTokens__text {
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    margin-right: 50px;
    font-family: 'GothamPro';
}

.moreTokens__btn {
    max-width: 226px;
    font-size: 12px;
    color: #ffce54;
}

.card__open-arrow {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
}

.transformatorCard-disabled {
    filter: grayscale(1);
    cursor: no-drop;
}

.transformatorCard:hover .transformatorCard__open {
    transform: initial;
}

.treeName {
    font-size: 26px;
    text-transform: uppercase;
    color: #8c96aa;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.treeName img {
    margin-left: 15px;
}

.cardFull__wrap {
    margin-top: 65px;
    display: grid;
    grid-gap: 20px;
}

.cardFull {
    display: flex;
}

.cardFull__left {
    margin-right: -10px;
}

.cardFull__imgWrap {
    width: 184px;
    height: 238px;
    position: relative;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
}

.cardFull__nr {
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    margin-right: 5px;
    font-family: 'GothamPro';
    position: absolute;
    bottom: 47px;
    left: 50%;
    transform: translate(-50%, 0);
}

.cardFull__right {
    flex: 1;
}

.cardFull__info {
    height: calc(100% - 56px);
    margin: 28px 0;
    padding: 17px 0 11px 0;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-left: none;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: #373b51;
}

.cardFullInfo__header {
    background-color: rgb(255 255 255 / 20%);
    display: flex;
    padding: 13px 30px;
}

.cardFullInfoHeader__row {
    display: flex;
    flex: 1;
    justify-content: space-between;
    position: relative;
}

.cardFullInfoHeader__row:after {
    content: "";
    clear: both;
    position: absolute;
    bottom: 3px;
    left: 0;
    width: calc(100% - 25px);
    height: 1px;
    border-bottom: 1px dashed #9598a1;
}

.cardFullInfoHeaderRow__label {
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    font-family: 'GothamPro';
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
    position: relative;
    z-index: 1;
    background-color: #5f6274;
    padding-right: 5px;
}

.cardFullInfoHeaderRow__value {
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    font-family: 'GothamPro';
    text-shadow: 1px 1px 0px rgb(0 0 0);
    position: relative;
    z-index: 1;
    padding-left: 5px;
    background-color: #5f6274;
}

.cardFullInfoHeader__row:first-of-type {
    padding-right: 25px;
    margin-right: 25px;
    border-right: 2px solid #fff;
    text-shadow: 1px 1px 0px rgb(0 0 0);
}

.cardFullInfoHeaderRow__value-span {
    text-decoration: underline;
    text-underline-offset: 2px;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
}

// .cardFullInfo__content {}

.cardFullInfoContent__title {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    font-family: 'GothamPro';
    text-align: center;
    margin: 23px 0;
    text-shadow: 1px 1px 0px rgb(0 0 0);
}

.cardFullInfoContent__title-span {
    font-size: inherit;
    font-family: inherit;
    text-shadow: inherit;
    color: #ffce54;
}

.cardFullInfoContent__btnWrap {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.cardFullInfoContent__btn {
    max-width: 280px;
    width: 100%;
    height: 46px;
    position: relative;
    border: 1px solid #fff;
    border-radius: 23px;
    background-color: #ffce54;
    cursor: pointer;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    font-family: 'GothamPro';
    text-transform: uppercase;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.8);
    transition: 500ms all;
}
.cardFullInfoContent__btn:not(.cardFullInfoContent__btn2) {
    padding-left: 35px;
}
.cardFullInfoContent__btn:hover {
    background-color: #ffb600;
}

.cardFullInfoContent__btn:focus {
    outline: none;
}

.cardFullInfoContent__btn-cyrcle {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #000000;
    box-shadow: 1px 1px 0px 0px rgba(255, 255, 255, 0.8);
    display: block;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}

.cardFullInfoContent__btn2 {

padding-right: 35px;
}

.cardFullInfoContent__btn2 .cardFullInfoContent__btn-cyrcle {
    left: initial;
    right: 20px;
}

.cardFullInfoContent__btn-collect {
    background-color: #64c200;
}

.cardFullInfoContent__btn-collect .cardFullInfoContent__btn-cyrcle {
    background-color: #ffffff;
    box-shadow: -1px -1px 0px 0px rgba(0, 0, 0, 0.8);
}

.cardFullInfoContent__btn-unfarm {
    background-color: #ed5564;
    color: #ffea00;
    text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.75);
}

.cardFullInfoContent__btn-unfarm .cardFullInfoContent__btn-cyrcle {
    background-color: #ffeb00;
    box-shadow: -1px -1px 0px 0px rgba(0, 0, 0, 0.8);
}

.cardFullInfoContent__btn-collect:hover {
    background-color: #509b00;
}

.cardFullInfoContent__btn-unfarm:hover {
    background-color: #ff0019;
}

.cardFullInfoHeaderRow__value-span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 220px; */
    position: relative;
    display: block;
}

.cardFullInfoHeaderRow__value-span:after {
    content: "";
    clear: both;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #fff;
}

.cardFullInfoContent__btn-disabled {
    filter: grayscale(1);
    cursor: no-drop;
}

@media(max-width: 1500px) {
    .moreTokens {
        margin-top: 50px;
    }
}

@media(max-width: 1200px) {
    .mainCards {
        margin-top: 40px;
    }

    .cardFull__wrap {
        margin-top: 40px;
    }
}

@media(max-width: 1024px) {
    .header__menu {
        margin: 0;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }

    .treeName {
        margin-bottom: 40px;
    }

    .moreTokens {
        margin-top: 30px;
    }

    .cardFullInfoContent__btn {
        max-width: 230px;
    }

    .cardFull__wrap {
        grid-gap: 10px;
    }
}

@media(max-width: 768px) {
    .moreTokens {
        flex-direction: column;
    }

    .moreTokens__text {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .moreTokens {
        margin-top: 10px;
    }

    .mainCards {
        margin-top: 30px;
    }

    .cardFull__wrap {
        margin-top: 30px;
    }

    .transformatorNavMenu__a {
        font-size: 12px;
    }

    .transformatorNavMenu__li {
        padding: 0px 10px;
    }

    .transformatorNavMenu {
        margin-top: 20px;
    }

    .cardFull {
        flex-direction: column;
    }

    .cardFull__info {
        border: 1px solid #ffffff;
        border-radius: 25px;
        margin: 0;
        padding: 70px 0 10px;
    }

    .cardFull__left {
        margin-right: initial;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -60px;
    }

    .cardFullInfo__header {
        padding: 13px 10px;
    }

    .cardFullInfoHeaderRow__label {
        font-size: 10px;
    }

    .cardFullInfoHeaderRow__value {
        font-size: 10px;
    }

    .cardFullInfoContent__title {
        font-size: 16px;
    }

    .cardFull__info {
        height: 100%;
    }

    .cardFullInfoContent__btnWrap {
        margin: 0 10px;
    }

    .cardFullInfoContent__btn {
        font-size: 10px;
    }

    .cardFull__wrap {
        margin-bottom: 30px;
        grid-gap: 20px;
    }
    
    .transformator .container {
        margin: 0;
        padding: 0;
    }
}

@media(max-width: 576px) {
    .transformator .container {
        margin: 0;
        padding: 0;
    }

    .cardFullInfoHeader__row:first-of-type {
        margin-right: 0;
        padding-right: 0;
        border-right: initial;
    }

    .cardFullInfo__header {
        gap: 10px;
    }

    .cardFullInfo__header {
        flex-direction: column;
    }

    .cardFullInfoHeader__row:after {
        bottom: 1px;
    }

    .cardFullInfoContent__title {
        line-height: 140%;
    }

    .cardFullInfoContent__btnWrap {
        flex-direction: column;
        align-items: center;
    }
}