@use '@styles/variables.scss';

.card__wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;

    .card__item{
        flex: 1; 
    }
}

.mnemonicWords__wrap{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    margin: 30px 0;

    .mnemonicWords__item {
        border: 1px solid #5454543b;
        border-radius: 6px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }
    .mnemonicWords__item-pointer{
        cursor: pointer;
    }
    .mnemonicWords__item-disabled{
        background: #e7e7e7;
    }
}

.downloadButton{
    cursor: pointer;
}