.header_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .header_image {
        width: 100%;
        object-fit: contain;
        aspect-ratio: 1 / 0.2;
    }
}