$yellow: #ffe400;
$faded_yellow: #fdfdbd;
$red: #ff0000;
$soft_red: #f44336;
$green: #30ff00;
$purple: #cc00ff;
$blue: #0090ff;
$gray: #d7d7d7;

$xxs: 475px;
$xs: 600px;
$sm: 768px;
$md: 992px;
$lg: 1200px;