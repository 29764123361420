@use '@styles/variables.scss';

.navbar {
    padding: 50px 0 30px 0;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navbar__item{
        margin: 0 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .navbar__itemIcon{
            margin-left: 10px;
            // color: variables.$colorBlack;
        }

        .navbar__title{
            // color: variables.$colorBlack;
            font-weight: bold;
            font-size: 18px;
        }
    }

    .navbar__logo{
        margin-right: 50px;

        .navbar__logo-link{
            text-decoration: none;
        }

        .navbar__logo-text{
            // color: variables.$colorBlack;
            font-weight: bold;
            font-size: 35px;
            font-family: 'Comfortaa', cursive !important;
        }
    }
    
}