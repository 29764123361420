@use 'GSHmodule';

.GOSHoldCard {
   color: white;
   padding: 0.3rem;
   border: 0.1rem solid #73af91;
   background-image: -webkit-radial-gradient(50% 50%, circle closest-side, #5c6a78 0%, #47545f 100%);
   border-radius: 1rem;
   aspect-ratio: 1 / 1.4;
   box-sizing: border-box;

   @media screen and (max-width: GSHmodule.$md) {
      aspect-ratio: 1 / 1.2;
   }

   @media screen and (max-width: GSHmodule.$sm) {
      aspect-ratio: 1 / 1.1;
   }

   @media screen and (max-width: GSHmodule.$xs) {
      aspect-ratio: 1 / 0.85;
   }

   @media screen and (max-width: GSHmodule.$xxs) {
      aspect-ratio: 1 / 1.1;
   }

   .image_wrapper {
      height: 50%;

      .image {
         object-fit: cover;
         border-radius: 0.5rem;
         height: 100%;
         width: 100%;
      }
   }

   .data_wrapper {
      height: 50%;
      box-sizing: border-box;
      padding: 0 0.2rem;

      .text_wrapper {
         height: 80%;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         justify-content: center;
         row-gap: 0.5rem;

         .text {
            font-size: 0.9rem;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media screen and (max-width: GSHmodule.$lg) {
               font-size: 0.9rem;
            }

            @media screen and (max-width: GSHmodule.$sm) {
               font-size: 1rem;
            }

            @media screen and (max-width: GSHmodule.$xs) {
               font-size: 1.3rem;
            }

            @media screen and (max-width: GSHmodule.$xxs) {
               font-size: 1.1rem;
            }

            .text_heading {
               font-weight: bold;
               color: #01fe72;
            }

            .text_details {
               font-weight: bold;
            }
         }
      }

      .button_wrapper {
         height: 20%;
         display: flex;
         align-items: center;
         justify-content: center;
      }
   }
}