$primary-blue: #113251;
$contentBorder: 1px solid #242435;

$darkBlue: #0d0d3c;
$vanillaBlue: #272750;
$grayishBlue: #4a4a6d;
$blurryBlue: #36368e;
$bloodyRed: #ba0000;

.codex2023_container {
    width: 100%;
    border-radius: 1rem;
    border: solid 0.5rem $darkBlue;
    padding: 1rem 1rem 2rem;
    box-sizing: border-box;
    background-color: $vanillaBlue;

    .maDetailContentMain__value {
        color: $bloodyRed;
        text-shadow: 0 0 0.1rem black;
    }

    .maDetailTop__titleWrap {
        margin-top: 4rem;

        @media(max-width: 1500px) {
            margin-top: 3rem;
        }

        @media(max-width: 768px) {
            margin-top: 1rem;
        }

        @media(max-width: 576px) {
            margin-top: 0;
        }
    }

    .collectionWrap {
        background: none;
    }

    .maMain__menuWrap {
        .maMainMenuLink__hover {
            width: 125px;
            height: 125px;
            top: 8.3rem;
        }
    }

    .maCollectionContent__link {

        .maMainMenuLink__hover {
            width: 110px;
            height: 110px;
            top: 6.5rem;

            @media(max-width: 576px) {
                width: 95px;
                height: 95px;
                top: 5.4rem;

                .maMainMenuLinkHover__arrow {
                    width: 30px;
                    height: 30px;
                }

                .maMainMenuLinkHover__text {
                    font-size: 13px;
                }
            }
        }
    }

    .maMainMenuLinkHover__text {
        font-size: 15px;
    }

    .maLogo__imgWrap {
        border: $contentBorder;
        background-color: $darkBlue;
    }

    .maLogo__imgContent {
        background-color: $vanillaBlue;
    }

    .maLogo__textWrap {
        background-color: $darkBlue;
    }

    .ma__top:after {
        border: 1px solid $grayishBlue;
        background-color: $darkBlue;
    }

    .maCollection__content1 {
        border: solid 1px $grayishBlue;
        background-color: $darkBlue;
        row-gap: 1rem;
    }

    .ma__informer {
        border: solid 1px $grayishBlue;
        background-color: $darkBlue;
    }

    .maMain__menuLink {
        box-shadow: 6px 8px 15px 0px $blurryBlue;
    }

    .maMain__menuWrap {
        .maMainMenuLink__number {
            left: calc(50% + 3.5rem);
            bottom: 0.7rem;
            color: white;
        }
    }

    .collectionWrap {
        .maMainMenuLink__number {
            left: calc(50% + 2.6rem);

            @media(max-width: 576px) {
                left: calc(50% + 2.1rem);
            }
        }
    }

    .maDetailTop__subTitle {
        color: $bloodyRed;
        text-shadow: 0 0 0.3rem black;
    }
}

.series3Nft {

    .bodyContent {
        background: linear-gradient(326deg, rgba(51, 6, 20, 1) 0%, rgba(140, 17, 54, 1) 68%, rgba(255, 70, 126, 1) 100%);
        position: relative;
    }

    .bodyContent:after {
        content: "";
        clear: both;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: url(/assets/images/nft-codex-2023/bg.png);
        // background-position: center top;
        // background-repeat: no-repeat;
    }

    #root {
        position: relative;
        z-index: 1;
    }

    .ma__content {
        background-color: #272750;
        border-radius: 25px;
        position: relative;
    }

    .ma__backBtn {
        color: #fff;
    }

    .maMain__menuLink {
        height: 265px;
        box-shadow: 6px 8px 15px 0px $blurryBlue;
    }

    .maMainMenuLink__hover {
        width: 134px;
        height: 134px;
        top: 51%;
    }

    .maMainMenuLink__number {
        bottom: 14px;
        left: 0;
        transform: initial;
        width: calc(100% - 23px);
        text-align: right;
        color: rgb(243 237 229 / 35%);
        font-size: 15px;
    }

    .maMainMenuLink__number-red {
        text-shadow: 0px 1px 0px rgb(0 0 0 / 50%);
        color: #f3ede5;
        font-size: 15px;
    }

    .collectionWrapRow__elem {
        color: #000000;
        font-weight: 500;
    }

    .collectionWrapRowElem__value {
        color: #000000;
    }

    .collectionWrapRowElem__value-line {
        background-color: #000;
    }

    .maCollection__content1 {
        background-color: #0d0d3c;
    }

    .maCollection__content1 .maMainMenuLink__number {
        bottom: 8px;
        width: calc(100% - 16px);
    }

    .collectionWrap {
        border: 1px solid $grayishBlue;
    }

    .ma__informer {
        background-color: var(--main-faded-blue);
        color: #ffffff;
        line-height: 140%;
        font-size: 18px;
        border-radius: 75px;
    }

    .ma__wrap {
        border: 2px solid rgb(255 255 255 / 25%);
        background-color: rgb(13 13 60);
        border-radius: 25px;
    }

    .ma__wrap:before,
    .ma__wrap:after {
        content: none;
    }

    .maLogo__imgContent {
        background-color: rgb(234 205 214);
    }

    .maLogo__imgWrap {
        overflow: initial;
        background-color: initial;
    }

    .maLogo__imgWrap:before {
        content: "";
        clear: both;
        position: absolute;
        width: calc(100% - 4px);
        height: 77px;
        border-radius: initial;
        border-top-left-radius: 80px;
        border-top-right-radius: 80px;
        top: 1px;
        left: 0px;
        border: 2px solid rgb(255 255 255 / 25%);
        border-bottom: none;
        background-color: #0d0d3c;
    }

    .maLogo__imgContent {
        padding: 12px;
        background-color: rgb(39 39 80);
        border-radius: 50%;
        position: relative;
        z-index: 1;
    }

    .loaderBlock {
        position: relative;
        z-index: 1;
    }

    .loaderBlock .maLogo__imgWrap::before {
        content: none;
    }

    .maMenu2__link {
        flex: 1;
    }

    .maMenu2__link {
        font-size: 16px;
    }

    .ma__top:after {
        border: 1px solid #4a4a6d;
        background-color: rgb(13 13 60);
    }

    .metaAristippus {
        position: relative;
        z-index: 1;
    }

    .maLogo__textWrap {
        background-color: rgb(13 13 60 / 80%);
    }

    .maMain__series {}

    .maMainSeries__link {
        color: #28242c;
        font-weight: 400;
        position: relative;
    }

    .maMainSeries__link:not(:nth-of-type(3)) {
        margin-right: 17px;
        padding-right: 17px;
    }

    .maMainSeries__link:not(:nth-of-type(3)):after {
        content: "";
        clear: both;
        position: absolute;
        top: 0;
        right: -2px;
        width: 2px;
        height: 100%;
        background-color: rgb(40 36 44 / 30%);
    }

    span.maMainSeries__link:after {
        content: none !important;
    }

    span.maMainSeries__link {
        margin-right: 0 !important;
        padding-right: 0 !important;
        border-right: none;
    }

    .maMainSeries__link-active {
        color: #3c5bc8;
        font-weight: bold;
        text-underline-offset: 3px;
    }

    .maMain__headerContent {
        max-width: 884px;
        width: 100%;
        margin: 50px auto 0;
        display: grid;
        grid-gap: 20px;
    }

    .maMainHeaderContent__text {
        color: #28242c;
        font-size: 16px;
        line-height: 140%;
    }

    .maMainHeaderContent__text-right {
        text-align: right;
    }

    .maMainHeaderContent__text-small {
        font-size: 14px;
    }

    .maCollectionContent__link {
        height: 212px;
    }

    .maCollectionContent__link .maMainMenuLink__hover {
        width: 122px;
        height: 122px;
    }

    .maCollectionContent__link .maMainMenuLinkHover__text {
        font-size: 14px;
    }

    .maCollection__row .maCollectionRowRight__bottom {
        position: relative;
    }

    .maCollection__row .maCollectionRowRight__bottom:after {
        content: "";
        clear: both;
        position: absolute;
        top: 33px;
        left: -40px;
        width: calc(100% - 120px);
        height: 78px;
        background-color: #feffe3;
        border-top-right-radius: 54px;
        border-bottom-right-radius: 54px;
    }

    .maCollectionRowRightBottom__left,
    .maCollectionRowRightBottom__right {
        position: relative;
        z-index: 1;
    }

    .maDetail__content {
        background-color: #faeaef;
    }

    .maDetailContentMain__value {
        text-shadow: 0px 0px 0.1rem black;
        color: $bloodyRed;
        left: initial;
        transform: initial;
        right: 40px;
        bottom: 30px;
    }

    .maCollectionRowRightTop__farmBlock {
        background-color: #4a4a6d;
        color: #fff;
    }

    .maCollectionRowRight__wrap {
        background-color: #272750;
        border: 1px solid #4a4a6d;
        border-left: 0;
    }

    .maCollection__row-multiplyer .maCollectionRowRight__wrap {
        border: 2px solid #fb7f00;
        border-left: 0;
    }

    .maCollection__row .maCollectionRowRight__bottom:after {
        content: none;
    }

    .maCollectionRowRightTop__farmBlock-red {
        color: #fb7f00;
    }

    @media(max-width: 1500px) {
        .maCollection__row .maCollectionRowRight__bottom:after {
            top: 24px;
        }

        .maDetailContentMain__value {
            right: 30px;
            bottom: 20px;
        }

        .maLogo__imgWrap {
            margin-top: -18px;
        }
    }

    @media(max-width: 1200px) {
        .collectionWrap .ma__collection {
            margin-top: 0;
        }

        .maCollection__content1 {
            padding: 30px;
        }

        .maMain__menuLink {
            margin: 0;
        }

        .maMain__menuWrap {
            gap: 20px;
        }

        .ma__informer {
            margin: 10px auto 40px;
        }

        .maCollection__row .maCollectionRowRight__bottom:after {
            content: none;
        }

        .maTop__center {
            flex: 1;
        }

        .maMenu2__link {
            flex: initial;
        }

        .maMenu2__link {
            font-size: 14px;
        }
    }

    @media(max-width: 1024px) {}

    @media(max-width: 768px) {
        /* .maCollection__content a {
            width: 164px;
            height: 248px;
            margin: 0 auto;
        } */

        .ma__informer {
            font-size: 16px;
            border-radius: 16px;
        }

        .ma__main {
            width: calc(100% - 20px);
            margin: 0 auto;
            box-sizing: border-box;
        }

        .maMenu2__link {
            flex: 1;
        }

        .maDetailContentMain__value {
            right: 20px;
            bottom: 14px;
            font-size: 15px;
        }
    }

    @media(max-width: 576px) {
        .maMain__textWrap {
            width: calc(100% - 20px);
        }

        .maMainHeaderContent__text {
            font-size: 14px !important;
        }

        .maMenu2__link {
            font-size: 12px !important;
        }

        .ma__collection:after {
            content: none;
        }

        .ma__informer {
            font-size: 14px;
        }

        .maMainSeries__link:not(:last-of-type) {
            margin-right: 7px !important;
            padding-right: 7px !important;
        }

        .maMainSeries__link {
            font-size: 11px !important;
        }

        .ma__collectionPage .maCollection__content1 {
            padding: 20px 10px;
        }

        .maDetailTop__title {
            font-size: 22px;
        }

        .maDetail__top {
            margin-bottom: 20px;
        }

        .maDetail__content {
            width: calc(100% - 10px);
            margin: 0 auto;
        }

        .maDetailCollection__row-combination-bg {
            font-size: 50px;
        }

        .series3Nft .maCollection__content1 {
            padding: 20px;
        }

        .maCollection__content1 {
            box-sizing: content-box;
            padding: 10px;
        }

        .maCollection__content {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            justify-content: center;
        }

        .maCollectionContent__link {
            height: 166px !important;
        }

        .maMainMenuLink__number-red {
            font-size: 12px;
        }

        .maCollection__content1 .maMainMenuLink__number {
            bottom: 6px;
        }

        .maLogo__imgWrap {
            margin-top: -38px;
        }

        .maCollectionContent__link .maMainMenuLink__hover {
            width: 102px;
            height: 102px;
        }

        .maCollectionContent__link .maMainMenuLinkHover__text {
            font-size: 11px;
        }
    }
}

.maDetailContentMain__info {
    background-color: #272750;
    border-color: #4a4a6d;
}