@use 'sass:list';

$text-sizes: 1.4rem, 1.2rem, 1.1rem, 1rem;

$white-color: #ffffff;
$smoke-color: #c5c5c5;
$cherry-color: #b40000;
$crimson-color: #DC143C;
$yellow-color: #ffff00;

@mixin custom_border($radius: 20px) {
    border: 1px solid $smoke-color;
    border-radius: $radius;
}

%text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.converter_container {
    @include custom_border();
    width: 100%;
    max-width: 800px;
    min-width: 290px;
    border-radius: 20px;
    padding: 20px;
    color: $white-color;
    box-sizing: border-box;

    .converter_header {
        font-size: list.nth($text-sizes, 1);
        font-weight: bold;
    }

    .converter_hr {
        border: none;
        border-top: 1px solid $smoke-color;
    }

    .content_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        gap: 10px;

        .content_heading {
            font-size: list.nth($text-sizes, 2);
            color: $smoke-color;
        }

        .content_balance {
            font-size: list.nth($text-sizes, 4);
            color: $smoke-color;
        }

        .content_input {
            @include custom_border(10px);
            width: 100%;
            max-width: 500px;
            min-width: 200px;
            height: 2rem;
            padding: 2px;
            font-size: list.nth($text-sizes, 3);
            text-align: center;
            vertical-align: middle;
        }

        .content_banner {
            @include custom_border(20px);
            box-sizing: border-box;
            width: 50%;
            padding: 5px;
            background-color: $cherry-color;
            border-color: $yellow-color;
            font-weight: bold;
            text-transform: uppercase;
            color: $yellow-color;
            font-size: list.nth($text-sizes, 2);
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            max-width: 135px;

            .banner_icon {
                width: 1.5rem;
            }

            .banner_text {
                @extend %text-ellipsis;
            }
        }
    }

    .converter_swap {
        @include custom_border(10px);
        display: block;
        margin: auto;
        padding: 5px;
        color: black;
        background-color: $smoke-color;
    }

    .converter_course {
        @include custom_border(10px);
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        text-align: center;
        word-wrap: break-word;
        text-transform: uppercase;
        font-size: list.nth($text-sizes, 3);
    }

    .converter_message {
        word-wrap: break-word;
        overflow-y: auto;
        margin: 10px 0;
        width: 100%;
        color: $crimson-color;
        height: 2.2rem;
        font-size: list.nth($text-sizes, 3);
        font-weight: bold;
    }

    .converter_exchange {
        @include custom_border(10px);
        @extend %text-ellipsis;
        box-sizing: border-box;
        width: 100%;
        display: block;
        margin: 10px auto;
        padding: 10px 0;
        font-size: list.nth($text-sizes, 3);
        font-weight: bold;
    }

    .converter_warning {
        @include custom_border(10px);
        box-sizing: border-box;
        padding: 10px;
        text-align: left;
    }
}

.alert_title {
    font-weight: unset;
    font-size: 18px;
}

@media only screen and (min-width: 600px) {}


@media only screen and (min-width: 768px) {}


@media only screen and (min-width: 992px) {}


@media only screen and (min-width: 1200px) {}