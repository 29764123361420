@font-face {
    font-family: 'Dreamscape';
    src: url('../../../../../fonts/Dreamscape/Dreamscape.woff2') format('woff2'),
        url('../../../../../fonts/Dreamscape/Dreamscape.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Palatino Linotype';
    src: url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Italic.eot);
    src: url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Italic.eot?#iefix) format('embedded-opentype'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Italic.woff2) format('woff2'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Italic.woff) format('woff'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Italic.ttf) format('truetype'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Italic.svg#PalatinoLinotype-Italic) format('svg');
    font-weight: 400;
    font-style: italic
}

@font-face {
    font-family: 'Palatino Linotype';
    src: url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-BoldItalic.eot);
    src: url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-BoldItalic.eot?#iefix) format('embedded-opentype'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-BoldItalic.woff2) format('woff2'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-BoldItalic.woff) format('woff'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-BoldItalic.ttf) format('truetype'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-BoldItalic.svg#PalatinoLinotype-BoldItalic) format('svg');
    font-weight: 700;
    font-style: italic
}

@font-face {
    font-family: 'Palatino Linotype';
    src: url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Roman.eot);
    src: url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Roman.eot?#iefix) format('embedded-opentype'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Roman.woff2) format('woff2'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Roman.woff) format('woff'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Roman.ttf) format('truetype'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Roman.svg#PalatinoLinotype-Roman) format('svg');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'Palatino Linotype';
    src: url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Bold.eot);
    src: url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Bold.eot?#iefix) format('embedded-opentype'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Bold.woff2) format('woff2'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Bold.woff) format('woff'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Bold.ttf) format('truetype'), url(../../../../../fonts/PalatinoLinotype/PalatinoLinotype-Bold.svg#PalatinoLinotype-Bold) format('svg');
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: 'Dreamscape';
    src: url(../../../../../fonts/Dreamscape/Dreamscape.woff2) format('woff2'),
        url(../../../../../fonts/Dreamscape/Dreamscape.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

$contentBorder: 1px solid #242435;
$bloodyRed: #ba0000;

.bodyContent {
    background: url(/images/aristippus_nft/bg.jpg);
    background-repeat: no-repeat;
    background-position: center top;
    background-color: #f7f5f6;
}

.aristippus_container {
    width: 100%;
    border-radius: 1rem;
    border: solid 0.5rem var(--main-faded-blue);
    padding: 1rem 1rem 2rem;
    box-sizing: border-box;
    background-color: var(--main-intensive-blue);

    .maDetailTop__titleWrap {
        margin-top: 4rem;

        @media(max-width: 1500px) {
            margin-top: 3rem;
        }

        @media(max-width: 768px) {
            margin-top: 1rem;
        }

        @media(max-width: 576px) {
            margin-top: 0;
        }
    }
}

.metaAristippus {
    margin-top: 70px;
}

.ma__wrap {
    /* border: 2px solid rgb(255 255 255 / 75%); */
    border-radius: 10px;
    padding: 8px;
    background: #38435b;
    position: relative;
    z-index: 1;
}

/* .ma__wrap:after {
    content: "";
    clear: both;
    position: absolute;
    width: calc(50% - 99px);
    height: 2px;
    top: 0px;
    right: 14px;
    background-color: rgb(255 255 255 / 75%);
}

.ma__wrap:before {
    content: "";
    clear: both;
    position: absolute;
    width: calc(50% - 99px);
    height: 2px;
    top: 0px;
    left: 14px;
    background-color: rgb(255 255 255 / 75%);
} */

.ma__content {
    background: #212e48;
    border-radius: 10px;
    position: relative;
}

.ma__top {
    margin: 0 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 30px 0px;
    /* padding: 22px 30px 44px; */
    position: relative;
}

.ma__top:after {
    content: "";
    clear: both;
    position: absolute;
    width: 100%;
    height: 48px;
    border-radius: 24px;
    background-color: #38435b;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    left: 50%;
    transform: translate(-50%, 0);
    border: $contentBorder;
}

.maTop__left {
    flex: 1;
    position: relative;
    z-index: 1;
}

.maMenu {
    display: flex;
}

.maMenu__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 24px;
    transition: 500ms all;
    opacity: 0.5;
    position: relative;
}

.maMenuLink__span {
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translate(-50%, 0);
    font-weight: 500;
}

.maMenu__link:hover,
.maMenu__link-active {
    opacity: 1;
}

.maTop__center {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 282px;
}

.maTop__center a {
    text-decoration: none;
}

.maTop__right {
    flex: 1;
    position: relative;
    z-index: 1;
}

.maLogo {
    position: relative;
}

.maLogo__imgWrap {
    /* border: 2px solid rgb(255 255 255 / 75%); */
    border-radius: 50%;
    padding: 8px;
    position: absolute;
    overflow: hidden;
    top: -110px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: hwb(221 22% 64% / 0.8);
    border: $contentBorder;
}

.maLogo__imgWrap:after {
    content: "";
    clear: both;
    position: absolute;
}

.maLogo__imgWrap:before {
    content: "";
    clear: both;
    position: absolute;
}


.maLogo__imgContent {
    padding: 12px;
    background-color: var(--main-intensive-blue);
    border-radius: 50%;
}

.maLogo__imgContent-img {
    max-width: 130px;
}

.maLogo__textWrap {
    text-align: center;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    background-color: rgb(56 67 91 / 80%);
    padding: 8px;
    box-shadow: -1px 0px 60px 0px rgba(0, 0, 0, 0.1);
}

.maLogoTextWrap__title {
    font-family: 'Palatino Linotype', 'Tahoma' !important;
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
}

.maLogoTextWrap__subTitle {
    color: #acacac;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Palatino Linotype', 'Tahoma' !important;
}

.maMenu2 {
    display: flex;
}

.maMenu2__link {
    /* flex: 1; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2d1454;
    font-size: 15px;
    transition: 500ms all;
    position: relative;
    text-align: center;
    margin-right: 32px;
}

.maMenu2__link:last-of-type {
    margin-right: 0;
}

.ma__cyrcleWrap {
    width: 177px;
    height: 177px;
    background-color: rgb(255 255 255 / 20%);
    border-radius: 50%;
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translate(-50%, 0);
    border: 2px solid #f8ede6;
    padding: 8px;
}

.ma__cyrcle {
    background-color: #fcfbfb;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}


.aristippus__informerDetil,
.aristippus__informerCollection,
.aristippus__informer {
    background-color: #fff;
    padding: 10px;
    border-radius: 50px;
    margin-top: 30px;
    box-shadow: -1px 0px 60px 0px rgba(0, 0, 0, 0.1);
}

.aristippus__informer {
    margin-top: 0px;
    margin-bottom: 70px;
}

.aristippus__informerDetailWrapper,
.aristippus__informerCollectionWrapper,
.aristippus__informerWrapper {
    background-color: #e7d1c3;
    border-radius: 46px;
    padding: 20px 30px;
}

.aristippus__informerDetil h3,
.aristippus__informerCollection h3,
.aristippus__informer h3 {
    font-size: clamp(20px, 2.4vw, 32px);
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #B61F25;

}

.aristippus__informerDetil p,
.aristippus__informerCollection p,
.aristippus__informer p {
    font-size: clamp(14px, 1.4vw, 18px);
    font-weight: 400;
    text-align: center;
    color: #B61F25;
}

.aristippus__informerDetil p strong,
.aristippus__informerCollection p strong,
.aristippus__informer p strong {
    font-weight: bold;
}

.ma__main {
    position: relative;
}

.ma__main {
    padding: 33px;
}

.maMain__textWrap {
    width: 100%;
    margin: 0 auto;
    margin-top: 2rem;
    text-align: center;
}

.maMain__text {
    // color: #acacac;
    color: #fff;
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 140%;
    font-weight: 500;
}

.maMain__text:last-of-type {
    margin-bottom: 0;
}

.maMain__menuWrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.maMain__menuLink {
    width: 175px;
    height: 262px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    transition: 500ms all;
    margin-right: 22px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 14px;
}

.maMain__menuLink:last-of-type {
    margin-right: 0;
}

.maMain__menuLink .maMain__menuLink__bg {
    filter: grayscale(0.85);
    background-size: contain !important;
}

.maMain__menuLink-yellow .maMain__menuLink__bg {
    width: 100%;
    height: 100%;
    // background: url(/images/aristippus_nft/menu/MA-Y-Aristippus.png);
    background-repeat: no-repeat;
    background-position: left top;
    position: absolute;
    left: 0;
    top: 0;
}

.maMain__menuLink-red .maMain__menuLink__bg {
    width: 100%;
    height: 100%;
    // background: url(/images/aristippus_nft/menu/MA-R-Aristippus.png);
    background-repeat: no-repeat;
    background-position: left top;
    position: absolute;
    left: 0;
    top: 0;
}

.maMain__menuLink-green .maMain__menuLink__bg {
    width: 100%;
    height: 100%;
    // background: url(/images/aristippus_nft/menu/MA-G-Aristippus.png);
    background-repeat: no-repeat;
    background-position: left top;
    position: absolute;
    left: 0;
    top: 0;
}

.maMain__menuLink-blue .maMain__menuLink__bg {
    width: 100%;
    height: 100%;
    // background: url(/images/aristippus_nft/menu/MA-B-Aristippus.png);
    background-repeat: no-repeat;
    background-position: left top;
    position: absolute;
    left: 0;
    top: 0;
}

.maMain__menuLink-purple .maMain__menuLink__bg {
    width: 100%;
    height: 100%;
    // background: url(/images/aristippus_nft/menu/MA-P-Aristippus.png);
    background-repeat: no-repeat;
    background-position: left top;
    position: absolute;
    left: 0;
    top: 0;
}

.maMainMenuLink__hover {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    top: 45%;
    transition: 500ms all;
    opacity: 0;
    z-index: 1;
    width: 165px;
    height: 165px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    flex-direction: column;
    background-color: rgb(0 0 0 / 75%);
}

.maMainMenuLinkHover__arrow {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: rgb(237 85 100 / 50%);
    margin: 0 auto 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
}

.maMain__menuLink-red .maMainMenuLinkHover__arrow {
    background-color: rgb(237 85 100 / 50%);
}

.maMain__menuLink-yellow .maMainMenuLinkHover__arrow {
    background-color: rgb(255 206 84 / 50%);
}

.maMain__menuLink-green .maMainMenuLinkHover__arrow {
    background-color: rgb(160 213 104 / 50%);
}

.maMain__menuLink-blue .maMainMenuLinkHover__arrow {
    background-color: rgb(79 193 232 / 50%);
}

.maMain__menuLink-purple .maMainMenuLinkHover__arrow {
    background-color: rgb(172 146 235 / 50%);
}

.maMainMenuLinkHover__text {
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
}

.maMainMenuLink__number {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    text-shadow: 0 0 0.1rem black;
    width: 100%;
    text-align: center;
}

.maMainMenuLink__number-red {
    color: $bloodyRed;
}

.maMainMenuLink__number-white {
    color: #fff;
}

.maMain__menuLink-active .maMain__menuLink__bg {
    filter: none;
}

.maMain__menuLink-active .maMainMenuLink__hover {
    display: none;
}

.maMain__menuLink:hover .maMainMenuLink__hover {
    opacity: 1;
}

.maMain__menuLink-disabled {
    cursor: no-drop;
}

.maMain__menuLink-disabled .maMainMenuLink__hover {
    display: none;
}

.ma__collection {
    margin-top: 20px;
}

.maCollection__content {
    /* padding: 32px;
    background-color: #ebebf5;
    border-radius: 25px; */
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 1rem;
}

.maCollectionContent__link {
    width: 140px;
    height: 209px;
    display: flex;
    position: relative;
    transition: 500ms all;
}

.maCollectionContentLink__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
}

.maCollectionContent__link .maMainMenuLink__number {
    bottom: 7px;
}

.maCollectionContent__link .maMainMenuLink__hover {
    width: 134px;
    height: 134px;
}

.maCollectionContent__link:hover .maMainMenuLink__hover {
    opacity: 1;
}

.maCollectionContent__link-disabled {
    cursor: no-drop;
}

.maCollectionContent__link-disabled .maCollectionContentLink__bg {
    filter: grayscale(0.85);
}

.maCollectionContent__link-disabled .maMainMenuLink__hover {
    display: none;
}

.ma__collection-yellow .maMainMenuLinkHover__arrow {
    background-color: rgb(255 206 84 / 50%);
}

.ma__collection-red .maMainMenuLinkHover__arrow {
    background-color: rgb(237 85 100 / 50%);
}

.ma__collection-blue .maMainMenuLinkHover__arrow {
    background-color: rgb(79 193 232 / 50%);
}

.ma__collection-purple .maMainMenuLinkHover__arrow {
    background-color: rgb(172 146 235 / 50%);
}

.ma__collection-green .maMainMenuLinkHover__arrow {
    background-color: rgb(160 213 104 / 50%);
}

.ma__informer {
    max-width: 770px;
    width: 100%;
    margin: 2rem auto;
    background-color: var(--main-strong-blue);
    border-radius: 62px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    padding: 45px 98px;
    font-weight: 500;
    line-height: 140%;
    border: $contentBorder;
}

.ma__bottomColor {
    display: flex;
    align-items: center;
    justify-content: center;
}

.maBottomColor__elem {
    display: flex;
    align-items: center;
    margin-right: 30px;
    transition: 500ms all;
    text-decoration: none;
}

.maBottomColor__cyrcle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
}

.maBottomColor__cyrcle-yellow {
    background-color: #ffce54;
}

.maBottomColor__text {
    color: #fff;
    font-size: 12px;
    opacity: 0.5;
    transition: 500ms all;
}

.maBottomColor__cyrcle-red {
    background-color: #ed5564;
}

.maBottomColor__cyrcle-green {
    background-color: #a0d568;
}

.maBottomColor__cyrcle-blue {
    background-color: #4fc1e8;
}

.maBottomColor__cyrcle-purple {
    background-color: #ac92eb;
}

.maBottomColor__elem:last-of-type {
    margin-right: 0;
}

.maBottomColor__elem-active .maBottomColor__text {
    opacity: 1;
}

.maBottomColor__elem:hover .maBottomColor__text {
    opacity: 1;
}

.maMain__currentMenuWrap-mobile {
    display: none;
}

.maMain__currentMenuWrapMobile {
    font-size: 16px;
    color: #28242c;
    font-weight: bold;
}

.maMain__currentMenuWrapMobile-red {
    color: #ba0000;
}

.maDetail__top {
    margin-bottom: 30px;
    position: relative;
}

.maDetailTop__prev {
    position: absolute;
    top: 0;
    left: 0;
    transition: 500ms all;
}

.maDetailTop__title {
    color: #fff;
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    line-height: 140%;
}

.maDetailTop__subTitle {
    color: #ba0000;
    font-size: 30px;
    font-weight: 900;
    text-shadow: 0 0 0.3rem black;
    text-align: center;
}

.maDetailTop__next {
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(180deg);
    transition: 500ms all;
}

.maDetail__content {
    padding: 30px;
    background-color: #38435b;
    border-radius: 25px;
}

.maDetailContent__main {
    display: flex;
    position: relative;
    z-index: 1;
}

.maDetailContentMain__left {
    width: 330px;
    position: relative;
}

.maDetailContentMain__imgWrap {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    border-radius: 23px;
    overflow: hidden;
}

.maDetailContentMain__right {
    margin: 20px 0;
    flex: 1;
    position: relative;
}

.maDetailContent__main-nitro .maDetailContentMain__right {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.maDetailContentMain__info {
    background-color: #212e48;
    border: 1px solid #ffffff;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    height: 90%;
    border-left: 0;
    padding: 20px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.maDetailContent__main-nitro .maDetailContentMain__info {
    border-color: #fb7f00;
    border-width: 2px;
    background-color: #ffffffde;
}

.maDetailContentMainInfo__row {
    display: flex;
    margin-bottom: 28px;
}

.maDetailContentMainInfo__title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    max-width: 180px;
    width: 100%;
    flex: 1;
}

.maDetailContentMainInfo__result {
    color: #fff;
    font-size: 18px;
    line-height: 140%;
    /* word-break: break-all; */
}

.maDetailContentMainInfo__elem-link {
    color: #00a3ff;
    font-size: 18px;
    text-decoration: underline;
    text-underline-offset: 3px;
}

.maDetailContentMainInfo__elem-link:hover {
    text-decoration: none;
}

.maDetailContentMainInfo__row:last-of-type {
    margin-bottom: 0;
}

.maDetailContentMain__value {
    position: absolute;
    left: 50%;
    bottom: 25px;
    transform: translate(-50%, 0);
    color: #FFFFFF;
    text-shadow: 0 0 0.1rem black;
    font-size: 24px;
    font-weight: bold;
}

.maDetailContent__price {
    margin: 30px 0 35px;
    background-color: #212e48;
    border: 1px solid #ffffff;
    border-radius: 10px;
    padding: 30px 40px;
}

.maDetailContentPrice__row {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.maDetailContentPrice__row:after {
    content: "";
    clear: both;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 9px;
    border-top: 1px dashed #fff;
}

.maDetailContentPrice__elem {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    background-color: #fff;
    z-index: 1;
}

.maDetailContentPrice__value {
    font-weight: bold;
    font-family: 'Bebas Neue' !important;
    font-size: 30px;
}

.gos__logo {
    font-family: "Bebas Neue" !important;
    color: #be739b;
}

.bonus__logo {
    font-family: inherit !important;
    color: #8787cd;
    font-size: 17px;
}

.maDetailContentPrice__elem:first-of-type {
    display: flex;
    align-items: flex-end;
    margin-bottom: 6px;
    padding-right: 10px;
}

.maDetailContentPrice__elem:last-of-type {
    padding-left: 10px;
}

.maDetailContent__btnWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.maDetailContentBtnWrap__left {
    width: 100%;
}

.maDetailContent__btn {
    max-width: 254px;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #005281;
    border-radius: 33px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    transition: 500ms all;
    text-transform: uppercase;
    cursor: pointer;
}

.maDetailContent__btn-disabled {
    background: #cacaca;
    cursor: no-drop;
}

.maDetailContentBtnWrap__right {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    flex: 1;
}

.maDetailContent__btn:not(.maDetailContent__btn-disabled):hover {
    background-color: #13131d;
}

.maDetailContentBtnWrap__right .maDetailContent__btn {
    margin-right: 10px;
}

.maDetailContentBtnWrap__right .maDetailContent__btn:last-of-type {
    margin-right: 0;
}

.maDetailTop__btnLink:hover {
    opacity: 0.4;
}

.maDetailContentPrice__row-green {
    margin-top: 20px;
    position: relative;
}

.maDetailContentPrice__row-green::before {
    content: "";
    clear: both;
    position: absolute;
    height: 48px;
    width: calc(100% + 60px);
    bottom: -5px;
    right: -40px;
    background-color: #38435b;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
}

.maDetailContentPrice__row-green .maDetailContentPrice__elem {
    background-color: #38435b;
}

.gico__logo {
    font-family: 'Dreamscape' !important;
    color: #00a3ff;
    font-size: 14px;
}

.switch {
    position: relative;
    width: 314px;
    margin: 0 auto;
    height: 64px;
}

.switch-checkbox {
    display: none;
}


/*Style words and oval switch */

.switch-labels {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 33px;
    height: 100%;
    width: 100%;
}

.switch-text {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.switch-text:before,
.switch-text:after {
    float: left;
    width: 50%;
    line-height: 30px;
    color: white;
    box-sizing: border-box;
    height: 100%;
}

.switch-text:before {
    content: "UNFARM";
    padding-left: 10px;
    color: #ff0000;
    background: -moz-linear-gradient(90deg, rgb(170, 200, 135) 0%, rgb(223, 255, 186) 100%);
    background: -webkit-linear-gradient(90deg, rgb(170, 200, 135) 0%, rgb(223, 255, 186) 100%);
    background: -ms-linear-gradient(90deg, rgb(170, 200, 135) 0%, rgb(223, 255, 186) 100%);
    background: linear-gradient(90deg, rgb(170, 200, 135) 0%, rgb(223, 255, 186) 100%);
    color: #565656;
    font-size: 16px;
    font-weight: 600;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    padding-left: 50px;
}

.switch-text:after {
    content: "FARM";
    padding-right: 10px;
    color: #FFFFFF;
    text-align: right;
    background-image: -webkit-linear-gradient(90deg, rgb(170, 200, 135) 0%, rgb(223, 255, 186) 100%);
    background-image: linear-gradient(90deg, rgb(170, 200, 135) 0%, rgb(223, 255, 186) 100%);
    color: #565656;
    font-size: 16px;
    font-weight: 600;
    text-shadow: 1px 1px 0px rgb(255 255 255);
    display: flex;
    align-items: center;
    padding-right: 50px;
    justify-content: flex-end;
    filter: grayscale(1);
    transition: 500ms all;
}


/*Style center dot*/

.switch-dot {
    width: 160px;
    height: 55px;
    position: absolute;
    top: 50%;
    bottom: 0;
    right: 144px;
    margin-right: 5px;
    border-radius: 33px;
    transition: all 0.3s ease-in 0s;
    transform: translate(0, -50%);
    background-image: -webkit-linear-gradient(90deg, rgb(223, 255, 186) 0%, rgb(170, 200, 135) 100%);
    border: 1px solid #a7a7a7;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(1);
}

/*State changer*/

.switch-checkbox:checked+.switch-labels .switch-text {
    margin-left: 0;
}

.switch-checkbox:checked+.switch-labels .switch-dot {
    right: 0px;
    /* margin-right: 0px; */
    background-image: -moz-linear-gradient(90deg, rgb(223, 255, 186) 0%, rgb(170, 200, 135) 100%);
    background-image: -webkit-linear-gradient(90deg, rgb(223, 255, 186) 0%, rgb(170, 200, 135) 100%);
    background-image: -ms-linear-gradient(90deg, rgb(223, 255, 186) 0%, rgb(170, 200, 135) 100%);
    border: 1px solid #b6d396;
    filter: grayscale(0);
}

.switchDot__on {
    display: none;
    color: #ce0000;
    font-size: 30px;
    font-family: 'Bebas Neue' !important;
    text-shadow: 1px 1px 0px rgb(255 255 255);
    transition: 500ms all;
}

.switchDot__off {
    display: flex;
    transition: 500ms all;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.switch-checkbox:checked+.switch-labels .switchDot__on {
    display: block;
}

.switch-checkbox:checked+.switch-labels .switchDot__off {
    display: none;
}

.switchDotoText {
    color: #ce0000;
    font-size: 12px;
    font-family: 'Dreamscape' !important;
}

.switchDotoText__gico {
    font-weight: inherit;
    font-family: inherit !important;
    font-size: inherit;
    text-shadow: 1px 1px 0px rgb(255 255 255);
}

.switchDotoText2 {
    color: #ce0000;
    font-size: 12px;
    text-shadow: 1px 1px 0px rgb(255 255 255);
}

.maDetailContentBtnWrap__checkbox {
    margin-left: 34px;
}

.maCollection__content1 {
    display: flex;
    flex-direction: column;
    background-color: var(--main-faded-blue);
    border-radius: 25px;
    padding: 27px 30px 47px;
}

.maCollection__row {
    display: flex;
    align-items: center;
}

.maCollectionRow__right {
    width: 100%;
    position: relative;
}

.maCollectionRowRight__wrap {
    background-color: #212e48;
    border: 1px solid #ffffff;
    border-left: none;
    border-top-right-radius: 107px;
    border-bottom-right-radius: 107px;
    padding: 23px 0 23px 35px;
    overflow: hidden;
}

.maCollection__row-nitro .maCollectionRowRight__wrap {
    border-color: #fb7f00;
    border-width: 2px;

    background-color: #ffffffde;
}

.maCollection__promotion {
    position: absolute;
    right: 30px;
    top: -19px;
    z-index: 5;
    height: 41px;
    width: 41px;
}

.maDetailContentMain__right .maCollection__promotion {

    right: -8px;
    top: -8px;
}

.maCollection__promotion-nitro {
    width: 41px;
    height: 41px;
}

.maCollection__promotion:nth-child(2) {
    right: 39px;
    top: -10px;
}

.maCollection__promotion:nth-child(3) {
    top: 36px;
    right: -17px;
}


.maCollectionRowRight__top {
    display: flex;
}

.maCollectionRowRightTop__left {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 35px;
}

.maCollectionRowRightTop__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 9px;
    position: relative;
}

.maCollectionRowRightTop__row:last-of-type {
    margin-bottom: 0;
}

.maCollectionRowRightTop__elem {
    position: relative;
    z-index: 1;
}

.maCollectionRowRightTop__elem-name {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding-right: 5px;
}

.maCollectionRowRightTop__elem-sep {
    border-top: 1px dashed #7b7b7b;
    flex-grow: 1;
    align-self: flex-end;
    padding: 0px;
}

.maCollectionRowRightTop__elem-value {
    color: #fff;
    font-size: 16px;
    padding-left: 5px;
}

.maCollectionRowRightTop__right {
    /* flex: 1.2; */
    flex: 0.8;
}

.maCollectionRowRightTop__farmBlock {
    font-size: 18px;
    font-weight: 600;
    color: #28242c;
    padding: 16px 10px 16px 48px;
    background-color: #fff;
    border-top-left-radius: 43px;
    border-bottom-left-radius: 43px;
    line-height: 105%;
    z-index: 2;
}

.maCollection__row-farm .maCollectionRowRightTop__farmBlock {
    background-color: #dafbb6;
}

.maCollectionRowRightTop__farmBlock-red {
    color: #ce0000;
    font-weight: inherit;
    font-size: inherit;
}

.maCollectionRowRightTop__farmBlock .gico__logo {

    color: inherit;

    font-size: inherit;
}

.maCollectionRowRight__bottom {
    display: flex;
    margin-top: -20px;
}

.maCollectionRowRightBottom__title {
    color: #2d1454;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}

.maCollectionRowRightBottomPrice__elem {
    color: #2d1454;
    font-size: 30px;
    font-weight: bold;
    font-family: 'Bebas Neue' !important;
    line-height: 100%;
}

.maCollectionRowRightBottomPrice__elem .bonus__logo {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
}

.maCollectionRowRightBottom__right {
    display: flex;
    justify-content: flex-start;
    /* margin-right: 55px; */
    flex: 1;
    align-items: flex-end;
}

.switch:hover .switch-text:after {
    filter: grayscale(0);
}

.switch:hover .switch-dot {
    right: 0px;
    filter: grayscale(0);
}

.collectionWrap {
    border: 1px solid #242435;
    background-color: #38435b;
    border-radius: 23px;
}

.collectionWrap__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 60px);
    margin: 47px auto 0;
    position: relative;
    background-color: #13131C;
    padding: 10px 40px;
    box-sizing: border-box;
    border-radius: 30px;
}

.collectionWrapRow__elem {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    z-index: 1;
    background-color: #13131c;
}

.collectionWrapRow__elem-valueWrap {
    display: flex;
}

.collectionWrapRowElem__value {
    color: #fff;
    font-size: 30px;
    font-family: 'Bebas Neue' !important;
    font-weight: bold;
}

.collectionWrapRowElem__value-line {
    width: 2px;
    height: 28px;
    margin: 0 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
}

.maCollection__content1 .maDetailContent__btn {
    height: 45px;
}

.maCollection__content1 .switch {
    height: 45px;
    width: 204px;
}

.maCollection__content1 .switch-dot {
    height: 40px;
    width: 103px;
}

.maCollection__content1 .maDetailContent__btn {
    max-width: 170px;
}

.maCollection__content1 .switch-text:before {
    padding-left: 20px;
    font-size: 14px;
}

.maCollection__content1 .switch-text:after {
    padding-right: 20px;
    font-size: 14px;
}

.maCollection__content1 .switch-dot {
    right: 91px;
}

.maCollection__content1 .maDetailContentBtnWrap__checkbox {
    margin-left: 15px;
}

.collectionWrap__row:after {
    content: "";
    clear: both;
    position: absolute;
    width: calc(100% - 80px);
    height: 1px;
    border-top: 1px dashed rgb(255 255 255 / 20%);
    left: 50%;
    bottom: 18px;
    transform: translate(-50%, 0);
}

.collectionWrapRow__elem:first-of-type {
    padding-right: 5px;
}

.collectionWrapRow__elem:last-of-type {
    padding-left: 5px;
}

.collectionWrap {
    margin-top: 2rem;
}

.ma__collectionPage {
    margin-top: -40px;
}

.maCollectionRowRightTop__farmCount {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.maCollectionRowRightTopFarmCount__elem {
    background-color: #dafbb6;
}

.maCollectionRowRightTopFarmCount__elem-name {
    color: #28242c;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    z-index: 1;
    padding-right: 5px;
}

.maCollectionRowRightTopFarmCount__elem-value {
    color: #ce0000;
    font-size: 17px;
    font-weight: 600;
    position: relative;
    z-index: 1;
    padding-left: 5px
}

.maCollectionRowRightTopFarmCount__elem-value .gico__logo {
    font-size: inherit;
    color: inherit;
}

.maCollectionRowRightTop__farmCount:after {
    content: "";
    clear: both;
    position: absolute;
    width: 100%;
    height: 1px;
    border-top: 1px dashed #7b7b7b;
    left: 0;
    bottom: 10px;
}

.maCollectionRowRightTop__farmCountWrap {
    padding: 0 48px 0px 48px;
    background-color: #dafbb6;
    border-top-left-radius: 43px;
    border-bottom-left-radius: 43px;
    display: flex;
    justify-content: space-between;
    height: 86px;
    align-items: center;
    position: relative;
}

.ma__backBtn {
    color: #000e41;
    font-size: 16px;
    font-weight: bold;
    transition: 500ms all;
    display: inline-block;
    cursor: pointer;
}

.ma__backBtnWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 44px;
}

.btn__mb20 {
    margin-bottom: 20px;
}

.btn__mt20 {
    margin-top: 20px;
}

.ma__backBtn:hover {
    transform: scale(1.1);
    text-decoration: underline;
    text-underline-offset: 3px;
}

.maPurchase {
    display: flex;
    margin-top: 70px;
}

.maPurchase__left {
    flex: 1;
    margin-right: 23px;
}

.maPurchase__title {
    color: #b71f25;
    font-size: 20px;
    font-weight: bold;
}

.maPurchase__textWrap {
    margin: 30px 0;
}

.maPurchase__text {
    color: #28242c;
    font-size: 16px;
    margin-bottom: 25px;
}

.maPurchase__text:last-of-type {
    margin-bottom: 0;
}

.maPurchase__price {
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    height: 68px;
    align-items: center;
    padding: 0 23px 0 39px;
    position: relative;
}

.maPurchasePrice__text {
    color: #1e1e1e;
    font-size: 20px;
    font-weight: bold;
}

.maPurchasePrice__value {
    font-family: 'Bebas Neue' !important;
    font-weight: bold;
    color: #2d1454;
    font-size: 30px;
}

.maPurchasePrice__value-bonus {
    font-family: 'Bebas Neue' !important;
    font-weight: bold;
    color: #8787cd;
    font-size: 18px;
}

.maPurchase__accord {
    margin-top: 9px;
}

.maPurchaseAccord__top {
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    height: 68px;
    align-items: center;
    padding: 0 23px 0 39px;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.maPurchaseAccordTop__text {
    color: #1e1e1e;
    font-size: 20px;
    font-weight: bold;
}

.maPurchaseAccordTop__arrow {
    width: 10px;
    height: 10px;
    border-right: 2px solid #b71f25;
    border-bottom: 2px solid #b71f25;
    transform: rotate(45deg);
    transition: 500ms all;
}

.maPurchaseAccord__content {
    display: none;
    border: 1px solid #cfcfcf;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none;
    margin-top: -10px;
    padding: 37px 39px 27px;
    background-color: #f7f7fc;
    transition: 500ms all;
}

.maPurchase__btnWrap {
    margin-top: 9px;
}

.maPurchase__btn {
    background-color: #b71f25;
    border-radius: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    color: #fefefe;
    font-size: 20px;
    font-weight: bold;
    transition: 500ms all;
    cursor: pointer;
}

.maPurchase__right {
    flex: 1;
    margin-left: 23px;
}

.maPurchase__btn:focus {
    outline: none;
}

.maPurchase__btn:hover {
    background-color: #7e1418;
}

.maPurchase__accord-opened .maPurchaseAccordTop__arrow {
    transform: rotate(-135deg);
}

.maPurchase__accord-opened .maPurchaseAccord__content {
    display: block;
}

.maPurchaseAccord__text {
    color: #28242c;
    font-size: 16px;
    margin-bottom: 27px;
}

.maPurchaseAccord__text:last-of-type {
    margin-bottom: 0;
}

.maPurchase__content {
    background-color: #ebebf5;
    border-radius: 25px;
}

.maPurchaseContent__top {
    height: 78px;
    background-color: #f7f7fc;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border: 1px solid #cfcfcf;
}

.maPurchaseContent__imgWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -100px;
    padding-bottom: 10px;
}

.maPurchaseContent__content {
    padding: 26px;
}

.whiteWrapper {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: -1px 0px 60px 0px rgba(0, 0, 0, 0.1);
    padding: clamp(15px, 4.4vw, 40px) clamp(10px, 2.2vw, 30px);
}

.maPayment {
    display: flex;
    justify-content: space-between;
}

.maPayment__left {
    flex: 2;
    margin-right: 10px;
}

.maPayment__right {
    flex: 3;
    margin-left: 10px;
}

.whiteWrapper__title {
    font-size: clamp(14px, 2.2vw, 30px);
    font-weight: bold;
    color: #ff0009;
    margin-bottom: clamp(15px, 2.2vw, 30px);
}

.whiteWrapper__p {
    color: #000;
    font-size: clamp(12px, 1.4vw, 20px);
    margin: 15px 0px;
}

.bttnCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.maSuccessPayment {
    background-color: #ebebf5;
    border-radius: 23px;
    padding: 50px;
}

.maSuccessPayment__title {
    color: #b92a30;
    font-size: clamp(20px, 2.7vw, 40px);
    font-weight: bold;
}

.maSuccessPayment__informer {
    background-color: #f7f7fc;
    border: 1px solid #b92a30;
    border-radius: 10px;
    padding: 40px;
    margin-top: 40px;
}

.maSuccessPaymentInformer__p {
    color: #28242c;
    font-size: clamp(12px, 1.2vw, 16px);
    line-height: 140%;
    font-weight: 500;
    text-align: center;
}

.maSuccessPayment__subTitle {
    color: #b92a30;
    font-size: 16px;
    font-weight: 500;
}

.maCollectionRowRightTop__row-token .maCollectionRowRightTop__elem-value {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.maDetailContentMainInfo__result {
    font-size: 17px;
    flex: 1;
}

.burnBtn__wrap {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 500ms all;
}

.burnBtn__wrap:hover {
    transform: scale(1.1);
}

.burnBtn__img {
    margin-right: 10px;
}

@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
    }

    25% {
        -moz-transform: rotate(-5deg);
    }

    75% {
        -moz-transform: rotate(5deg);
    }

    100% {
        -moz-transform: rotate(0deg);
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    25% {
        -webkit-transform: rotate(-5deg);
    }

    75% {
        -webkit-transform: rotate(5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    25% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    75% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.maLogo__imgContent-img-spinner {
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    animation: spin 2s linear infinite;
}

.loaderBlock {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.connectionError {
    font-size: 18px;
    background: #b31c1c;
    padding: 10px 20px;
    color: #ffffff;
    font-weight: bold;
}

.blurContent {
    filter: blur(5px);
}

.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #bb9b4c;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spinLoader 1s linear infinite;
}

.loader.loader-white {
    border: 5px solid #fff;
    border-top: 5px solid #c5c5c5;
    height: 20px;
    width: 20px;
}

@keyframes spinLoader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.multiplyer_wrap {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    background-image: -moz-linear-gradient(122deg, rgb(247, 78, 3) 0%, rgb(255, 162, 0) 100%);
    background-image: -webkit-linear-gradient(122deg, rgb(247, 78, 3) 0%, rgb(255, 162, 0) 100%);
    background-image: -ms-linear-gradient(122deg, rgb(247, 78, 3) 0%, rgb(255, 162, 0) 100%);
    box-shadow: 0px 0px 30px 0px rgb(126 126 126 / 30%);
    border-radius: 50%;
    top: -10px;
    left: -10px;
    filter: grayscale(1);
    z-index: 1;
}

.multiplyer_wrap-active {
    filter: initial;
}

.multiplyer_xLetter {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: -10px;
    margin-right: 2px;
}

.multiplyer_number {
    color: #fff;
    font-weight: bold;
    font-size: 30px;
}

.maMenu2__link-span {

    font-size: 8px;
    color: #bc5f5f;
    font-weight: 500;
    padding: 4px 6px;
    border: 1px solid #bc5f5f;
    border-radius: 10px;
    position: absolute;
    top: -10px;
    right: -33px;
}

.bountyCode__gift {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 10px;
    right: -10px;
    background-color: #ffd400;
    padding: 10px 10px 10px 20px;
    flex-direction: column;
    border-radius: 5px;
    height: 100%;
    top: 0;
    right: -10px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
}

.bountyCode__gift-leftBg {
    position: absolute;
    top: 0;
    left: -44px;
    background-color: #e4e4e4;
    width: 0;
    height: 0;
    border-bottom: 90px solid #fdd400;
    border-left: 92px solid transparent;
}

.bountyCode__gift-plus {
    position: absolute;
    left: -9px;
    font-size: 40px;
    top: 50%;
    transform: translate(0, -50%);
    color: #7c35d3;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #fdd400;
    font-weight: 300;
}

.bountyCodeGift__free {
    font-size: 14px;
    color: #3c5783;
    margin-bottom: 3px;
}

.bountyCodeGift__elem {
    display: flex;
    align-items: center;
}

.bountyCodeGiftElem__bounty {
    color: #fff;
    background-color: #7c35d3;
    padding: 5px 10px;
    font-size: 12px;
}

.bountyCodeGiftElem__bounty-code {
    font-weight: bold;
}

.maCollectionRowRightTop__farmBlock2 {
    position: relative;
    padding: 16px 10px 16px 28px;
}

.freeBountyCode {
    position: absolute;
    height: 57px;
    background-color: #ffd200;
    width: 380px;
    bottom: 20px;
    right: 2px;
    border-top-left-radius: 36px;
    border-bottom-left-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0px 2px 0px 0px rgb(0 0 0 / 15%);
}

.freeBountyCode__plus {
    color: #3c5783;
    font-size: 30px;
    font-weight: bold;
    margin-right: 36px;
}

.freeBountyCode__text {
    color: #3c5783;
    font-size: 24px;
    font-weight: bold;
    margin-right: 36px;
}

.freeBountyCode__logo {
    color: #ffffff;
    font-size: 26px;
    font-weight: 500;
    background-color: #7c35d3;
    padding: 4px 20px;
    border-radius: 36px;
}

.freeBountyCode__logo b {
    font-weight: 900;
}

.nftFarmInformer {
    background-color: #910000;
    padding: 30px 0;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    min-height: 311px;
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.nftFarmInformer__bgText-bg {
    background-color: #ffd200;
    position: absolute;
    top: 0;
    left: 0;
    width: 55%;
    height: 100%;
}

.nftFarmInformer__bgText-bg:after {
    content: "";
    clear: both;
    position: absolute;
    position: absolute;
    top: 0;
    right: -311px;

    width: 0;
    height: 0;
    border-bottom: 311px solid transparent;
    border-left: 311px solid #fdd200;
}

.nftFarmInformer__bgText {
    mix-blend-mode: soft-light;
    color: #fff;
    font-weight: 900;
    font-size: 120px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}

.nftFarmInformer__content {
    position: relative;
    z-index: 1;
    width: 100%;
}

.nftFarmInformerContent__title {
    max-width: 810px;
    width: calc(100% - 20px);
    margin: 0 auto;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ebebf5;
    border-radius: 36px;
    color: #3c5783;
    font-size: 24px;
    font-weight: bold;
    box-shadow: inset 0px 2px 0px 0px rgb(0 0 0 / 15%);
    text-align: center;
    text-transform: uppercase;
}

.nftFarmInformerContent__text {
    color: #3c5783;
    font-size: 24px;
    margin-left: 60px;
    margin: 28px 0 17px 60px;
    line-height: 170%;
}

.nftFarmInformerContent__bounty {
    color: #ffffff;
    font-size: 26px;
    font-weight: 500;
    background-color: #7c35d3;
    padding: 5px 20px;
    border-radius: 37px;
    margin-left: 12px;
    box-shadow: 0px 8px 35px 0px rgba(0, 0, 0, 0.3);
}

.nftFarmInformerContent__special {
    display: flex;
    align-items: center;
}

.nftFarmInformerContent__special-figure {
    height: 55px;
    width: 104px;
    background-color: #910000;
    position: relative;
    margin-right: 30px;
}

.nftFarmInformerContent__special-figure:after {
    content: "";
    clear: both;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 27.5px 0 27.5px 11px;
    border-color: transparent transparent transparent #910000;
    top: 0;
    right: -11px;
}

.nftFarmInformerContent__special-text {
    color: #900000;
    font-size: 20px;
}

.nftFarmInformerContent__click {
    position: absolute;
    bottom: 20px;
    right: 100px;
}

.maCollection__row-combination .maCollectionRowRight__wrap {
    border-color: #ffc1c1;
    border-width: 2px;
    background-color: #ffffffde;
    position: relative;
}

.maCollection__row .maCollection__row-combination-bg {
    position: absolute;
    bottom: 15px;
    right: 80px;
    font-size: 70px;
    font-weight: 800;
    line-height: 80%;
    color: rgb(255 255 255 / 10%);
    font-style: italic;
}

.maCollection__row .maCollectionRowRight__top {
    position: relative;
    z-index: 1;
}

.maCollection__row .maCollectionRowRight__bottom {
    position: relative;
    z-index: 1;
}

.maDetailContent__main-combination .maDetailContentMain__info {
    border-color: #ffc1c1;
    border-width: 2px;
}

.maDetailCollection__row-combination-bg {
    position: absolute;
    bottom: 20px;
    right: 50px;
    font-size: 250px;
    font-weight: 800;
    line-height: 80%;
    color: rgb(255 255 255 / 10%);
    font-style: italic;
    z-index: 1;
}

.maDetailContentMainInfo__row {
    position: relative;
    z-index: 2;
}


.freeBountyCode {
    z-index: 2;
}

.maMain__series {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.maMainSeries__link {
    color: #000000;
    font-size: 12px;
    font-weight: bold;

}

span.maMainSeries__link {
    margin-right: 18px;
    padding-right: 18px;
    border-right: 1px solid #000000;
}


.maMainSeries__link:hover {
    color: #3c5bc8;
    text-decoration: underline;
    text-underline-offset: 3px;
}

.maMainSeries__link-active {
    color: #3c5bc8;
    text-decoration: underline;
    text-underline-offset: 3px;
}

.maCollection__row-multiplyer .maCollectionRowRight__wrap {
    border: 2px solid #fb7f00;
    border-left: 0;
}

.detailPlusBlock {
    margin: 26px 0;
}

.detailPlusBlock__wrap {
    height: 130px;
    border: 1px solid #ac1707;
    border-radius: 10px;
    width: 100%;
    padding: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url(/images/aristippus_nft/detailPlus-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.detailPlusBlock__left {

    position: relative;

    z-index: 1;
}

.detailPlusBlock__text {
    color: #ac1707;
    font-size: 20px;
    font-weight: 500;
}

.detailPlusBlock__right {

    max-width: 355px;

    width: 100%;

    position: relative;

    z-index: 1;
}

.detailPlusBlock__btnWrap {
    width: 100%;
}

.detailPlusBlock__btn {
    height: 55px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ac1707;
    border-radius: 5px;
    border: 1px solid #750b00;
    transition: 500ms all;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    padding: 10px;
}

.detailPlusBlock__btn:focus {
    outline: none;
}

.detailPlusBlock__btn:hover {
    background-color: #750b00;
}

/* .maDetailContentMain__right .maCollection__promotion-icons .maCollection__promotion:nth-child(2) {
    right: -20px;
}

.maDetailContentMain__right .maCollection__promotion-icons .maCollection__promotion:nth-child(3) {
    right: -20px;
} */

.detailPlusBlock__title {
    color: #f2eac9;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.detailPlusBlock-activate .detailPlusBlock__text {
    color: #ffffff;
}

.detailPlusBlock__wrap {
    position: relative;
}

.detailPlusBlock-activate .detailPlusBlock__wrap:after {
    content: "";
    clear: both;
    position: absolute;
    top: 0;
    left: 0;
    background-image: -moz-linear-gradient(130deg, rgb(203, 96, 84) 0%, rgb(172, 23, 7) 100%);
    background-image: -webkit-linear-gradient(130deg, rgb(203 96 84 / 30%) 0%, rgb(172, 23, 7) 100%);
    background-image: -ms-linear-gradient(130deg, rgb(203, 96, 84) 0%, rgb(172, 23, 7) 100%);
    width: 100%;
    height: 100%;
    /* opacity: 0.8; */
}

.detailPlusBlock-activate .detailPlusBlock__btn {
    background-color: #f8f5e8;
    color: #ac1707;
}

.detailPlusBlock-activate .detailPlusBlock__btn:hover {
    background-color: #ac1707;
    color: #f8f5e8;
}

.purchaseForm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.counter__btn {
    height: 48px;
    width: 48px;
    border-radius: 10px;
    background-color: #f1f1f1;
    border: 1px solid #cfcfcf;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    transition: 500ms all;
}

.counter__btn:hover {
    background-color: #cfcfcf;
}

.counter__input::-webkit-outer-spin-button,
.counter__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.counter__input {
    height: 48px;
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    background-color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 100px;
    width: 100%;
    text-align: center;
    color: #1e1e1e;
    font-size: 20px;
    font-weight: 500;
    -moz-appearance: textfield;
}

.counter__input:focus {
    outline: none;
}

.purchaseForm__btnWrap {
    max-width: 200px;
    width: 100%;
}

.purchaseForm__btn {
    height: 48px !important;
}

.maCollection__content1 .maCollection__promotion:nth-child(2) {
    right: 70px;
    top: -19px;
}

.maCollection__content1 .maCollection__promotion:nth-child(2) {
    right: 70px;
    top: -19px;
}

.maCollection__content1 .maCollection__promotion:nth-child(3) {
    top: -19px;
    right: 110px;
}

body.es .nftFarmInformerContent__special-text {
    font-size: 17px;
}

.maPurchase__price-sale .maPurchasePrice__text {
    color: #fff;
}

.maPurchasePriceSale__span {
    position: absolute;
    background-color: #190622;
    top: -29px;
    right: 0px;
    padding: 5px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #efff00;
}

.maPurchase__price-sale .maPurchasePrice__value {
    color: #fff !important;
}

.maPurchase__price-sale .maPurchasePrice__value-bonus {
    color: #efff00;
}

.maPurchasePrice__valueWrap {
    position: relative;
}

.maPurchasePrice__valueOld {
    font-family: 'Bebas Neue' !important;
    font-weight: bold;
    color: #fff;
    font-size: 16px;
    text-align: center;

}

.maPurchasePrice__valueOld .maPurchasePrice__value-bonus {
    font-size: 14px;
}

.maPurchasePrice__valueOld-spanWrap {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    position: relative;
}

.maPurchasePrice__valueOld-span {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    opacity: 0.4;
}

.maPurchasePrice__valueOld-spanWrap:after {
    content: "";
    clear: both;
    position: absolute;
    top: 50%;
    left: -5px;
    width: calc(100% + 10px);
    transform: translate(0, -50%);
    height: 1px;
    background-color: #fff;
    z-index: 1;
}

.maPurchasePriceSale__span-white {
    color: #efff00;
    margin-right: 10px;
}

.maPurchasePriceSale__logo {
    max-width: 90px;
}

.notAmbassadorBtn {
    position: absolute;
    background-color: #190622;
    top: -30px;
    right: -2px;
    padding: 5px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: bold;
    color: #efff00;
    border: 1px solid #cfcfcf;
    border-bottom: none;
    transition: 500ms all;
}

.notAmbassadorBtn:hover {
    background-color: #b71f25;
}

.notAmbassadorBtn__wrap {
    position: relative;
    height: initial;
    padding: 10px 20px !important;
}

.notAmbassadorBtn .maPurchasePriceSale__span-white {
    margin-right: 3px;
}

.maPurchasePrice__wrap {
    width: 100%;
}

.maPurchasePrice__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.maPurchasePrice__row:last-of-type {
    margin-bottom: 0;
}

.maPurchasePrice__ambLogo {
    max-width: 120px;
    margin-left: 10px;
}

.maPurchasePrice__wrap .maPurchasePrice__row {
    padding: 10px;
}

.maPurchasePrice__row-ambassador .maPurchasePrice__text {
    display: flex;
    align-items: center;
    color: #fff;
}

.maPurchasePrice__row-ambassador .maPurchasePrice__value {
    color: #fff;
}

.maPurchasePrice__row-ambassador .maPurchasePrice__value-bonus {
    color: #efff00;
}

.ambassador__link {
    position: absolute;
    bottom: 12px;
    right: 20px;
    color: #c72525;
    font-weight: 500;
    text-underline-offset: 3px;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.ambassador__link:after {
    content: "";
    clear: both;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #c72525;
    transition: 500ms all;
}

.ambassador__link__logo {
    max-width: 80px;
    filter: invert(19%) sepia(48%) saturate(5496%) hue-rotate(351deg) brightness(92%) contrast(87%);
    margin-left: 7px;
}

.ambassador__link:hover:after {
    opacity: 0;
}

.canCollectAll-wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.farmInformer2 {
    border-radius: 12px;
    background-color: #8300ce;
    padding: 40px 0 30px;
    position: relative;
    overflow: hidden;
    margin-top: 40px;
}

.farmInformer2:after {
    content: "";
    clear: both;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(100% 0, 53% 0, 100% 100%);
    background-color: #192477;
}

.farmInformer2__title {
    box-shadow: inset 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
    background-color: rgb(255, 0, 0);
    border-bottom: 1px solid #ffc6c6;
    padding: 25px 0;
    text-align: center;
    text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.65);
    color: rgb(255, 246, 0);
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1px;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
}

.farmInformer2__center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 35px 0 20px;
    position: relative;
    z-index: 1;
}

.farmInformer2__left {
    color: #fff;
    font-size: 24px;
    letter-spacing: 1px;
    text-align: right;
}

.farmInformer2__bountyCode {
    color: #ffffff;
    font-size: 36px;
    font-weight: 500;
    background-color: rgb(124, 53, 211);
    box-shadow: 0px 8px 35px 0px rgba(0, 0, 0, 0.3);
    padding: 12px 30px;
    border-radius: 50px;
    margin-left: 20px;
}

.farmInformer2__bountyCode b {
    font-weight: 900;
}

.farmInformer2__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}

.farmInformer2Bottom__left {
    display: flex;
    align-items: center;
}

.farmInformer2Bottom__text {
    color: #b700e5;
    font-size: 60px;
    font-weight: 900;
    line-height: 80%;
    letter-spacing: 50px;
    margin-left: 55px;
}

.farmInformer2Bottom__right {
    color: #fee900;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-right: 50px;
}

.nftFarmInformerContent__special-figure2 {
    background-color: #ff0000;
    width: 70px;
    margin-right: 0;
}

.nftFarmInformerContent__special-figure2:after {
    border-color: transparent transparent transparent #ff0000;
}

.farmInformer2__img {
    position: absolute;
    right: 0;
    top: -17px;
}

.maDetailTop__titleWrap {
    margin-top: 30px;
}

.ma__collectionPage .maCollectionRowRightTop__farmCount:after {
    bottom: 3px;
}

.maCollectionContent__link>div {
    border-radius: 14px;
}

.maMain__menuLink-preloader {
    height: 100%;
}

.maMain__menuLink>div {
    border-radius: 14px;
}

.dao_enableFarming {
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    font-family: 'Gilroy' !important;
    text-transform: uppercase;
}

.grda__logo {
    font-family: inherit !important;
}

@media(max-width: 1500px) {
    .maCollection__content {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 1rem;
    }

    .maLogoTextWrap__title {
        font-size: 20px;
    }

    .maLogoTextWrap__subTitle {
        font-size: 12px;
    }

    .maMain__menuWrap {
        justify-content: center;
    }

    .maMain__menuLink {
        margin-right: 32px;
        margin-bottom: 20px;
    }

    .ma__top {
        padding: 40px 20px 30px;
    }

    .maMenu2__link {
        font-size: 12px;
    }

    .maMenuLink__span {
        font-size: 10px;
    }

    .maMenu__link {
        margin-right: 14px;
    }

    .maDetailContentMainInfo__result {
        font-size: 16px;
    }

    .maDetailContentMainInfo__title {
        font-size: 16px;
    }

    .maDetailContentMainInfo__elem-link {
        overflow-wrap: anywhere;
        font-size: 16px;
    }

    .maDetailContentMain__left {
        display: flex;
        align-items: center;
    }

    .maDetailContentMain__right {
        margin: 0;
    }

    .maDetailContentMain__info {
        position: relative;
        border-radius: 25px;
        padding-left: 40px;
        margin-left: -20px;
    }

    /* .maDetailContentMain__info:after {
        content: "";
        clear: both;
        position: absolute;
        top: -1px;
        left: -40px;
        width: 40px;
        height: 100%;
        background-color: #fff;
        border: 1px solid #cfcfcf;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        border-right: none;
    } */

    /* .maDetailContent__main-nitro .maDetailContentMain__info:after {
        border-color: #fb7f00;
        border-width: 2px;
        background-color: #ffffffde;
        height: calc(100% - 4px);
    } */

    .maDetailContentMain__left {
        position: relative;
        z-index: 2;
    }

    .maCollectionRowRightTop__farmBlock {
        font-size: 16px;
    }

    .maCollectionRowRightBottom__left {
        flex: 1;
    }

    .maCollectionRowRight__bottom {
        margin-top: 10px;
    }

    /* .maCollectionRowRightBottom__right .maDetailContent__btn {
        margin-bottom: 5px;
    } */

    .maCollectionRowRightBottom__title {
        font-size: 20px;
    }

    .maCollectionRowRightBottomPrice__elem {
        font-size: 24px;
    }

    .maCollectionRowRightTopFarmCount__elem-name {
        font-size: 16px;
    }

    .maCollectionRowRightTopFarmCount__elem-value {
        font-size: 18px;
    }

    .collectionWrap__row {
        flex-direction: column;
    }

    .collectionWrapRow__elem:first-of-type {
        margin-bottom: 10px;
    }

    .collectionWrap__row:after {
        content: none;
    }

    .maPurchase__price {
        padding: 0 20px;
    }

    .maPurchasePrice__value {
        font-size: 20px;
    }

    .maPurchasePrice__text {
        font-size: 16px;
    }

    .maPurchaseAccordTop__text {
        font-size: 16px;
    }

    .maPurchaseAccord__top {
        padding: 0 20px;
    }

    .maPurchase {
        flex-direction: column;
    }

    .maPurchase__left {
        margin-right: 0;
        margin-bottom: 50px;
    }

    .maPurchase__right {
        margin-left: 0;
    }

    .maPurchase__accord {
        width: 100%;
        max-width: initial !important;
    }

    .apexcharts-canvas {
        margin: 0 auto;
    }

    .maMenu2__link:not(:last-of-type) {
        margin-right: 12px;
    }

    .bountyCode__gift {
        width: 120px;
        padding: 10px;
    }

    .bountyCode__gift-leftBg {
        border-bottom: 82px solid #fdd400;
        border-left: 40px solid transparent;
        left: -40px;
        background-color: transparent;
    }

    .maCollectionRowRightTop__farmBlock2 {
        padding-left: 15px;
        font-size: 14px;
        min-height: 82px;
    }

    .bountyCode__gift-plus {
        left: -29px;
        font-size: 24px;
        width: 20px;
        height: 20px;
        font-weight: 500;
    }

    .freeBountyCode {
        width: 100%;
        position: relative;
        border-radius: 36px;
        top: initial;
        bottom: initial;
        left: initial;
        right: initial;
    }

    .maDetailContent__main-nitro .maDetailContentMain__info:after {
        height: 100%;
        top: -2px;
    }

    .nftFarmInformer__bgText-bg:after {
        right: -310px;
    }

    .nftFarmInformerContent__special-figure:after {
        right: -10px;
    }

    .nftFarmInformerContent__special-figure {
        width: 44px;
    }

    .purchaseForm {
        justify-content: flex-end;
    }

    .counter {
        margin-right: 30px;
    }

    .maDetailCollection__row-combination-bg {
        font-size: 160px;
    }

    .maDetailContentMain__right .maCollection__promotion {
        top: -25px;
        right: -10px;
    }

    .maCollection__promotion:nth-child(2) {
        right: 35px;
        top: -25px;
    }

    .maCollection__promotion:nth-child(3) {
        top: -25px;
        right: 80px;
    }

    body.es .nftFarmInformerContent__special-text {
        font-size: 13px;
    }

    .farmInformer2__img {
        right: -100px;
    }

    .farmInformer2Bottom__text {
        letter-spacing: 30px;
    }

    .farmInformer2__left {
        font-size: 20px;
    }

    .maCollection__content>a,
    .maCollection__content>div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .maCollection__content1 {
        grid-gap: 25px;
    }

    .maCollectionRowRightTop__farmBlock {
        padding: 10px 10px 10px 48px;
    }

    .maCollectionRowRightTop__farmCountWrap {
        height: 66px;
        padding: 0 28px;
    }

    .maCollectionRowRightTop__farmBlock {
        font-size: 14px;
    }

    .maCollectionRowRightTop__farmCount:after {
        bottom: 2px;
    }

    .maDetailContentMain__info {
        width: 100%;
        margin-left: 0;
        padding-left: 20px;
        padding-right: 20px;
    }

    .ma__informer {
        box-sizing: border-box;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        max-width: initial;
    }
}

@media(max-width: 1200px) {
    .maMain__menuLink {
        margin-right: 3px;
    }

    .ma__informer {
        padding: 45px;
    }

    .ma__top {
        margin: 0 10px;
        padding: 40px 10px 10px;
    }

    .maLogoTextWrap__title {
        font-size: 16px;
    }

    .maLogoTextWrap__subTitle {
        font-size: 10px;
    }

    .maDetailContent__main {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .maDetailContentMain__info:after {
        content: none;
    }

    .maDetailContentMain__info {
        border-radius: 25px;
        border: 1px solid #cfcfcf;
        padding-top: 80px;
        margin-top: -60px;
    }

    .maCollectionRowRight__bottom {
        flex-direction: column;
    }

    .maCollectionRowRightBottom__left {
        margin-bottom: 20px;
        padding: 0 15px;
    }

    .maCollectionRowRight__wrap {
        padding: 15px 0;
    }

    .maCollectionRowRightTop__left {
        padding: 0 15px;
    }

    .maCollectionRowRight__wrap {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        padding-left: 15px;
    }

    .maCollectionRow__left {
        position: relative;
        width: 170px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    /* .maCollectionRow__left::after {
        content: "";
        clear: both;
        position: absolute;
        top: 0px;
        right: -1px;
        height: calc(100% - 2px);
        width: 40px;
        background-color: #fff;
        border-top-left-radius: 60px;
        border-bottom-left-radius: 60px;
        border: 1px solid #cfcfcf;
        border-right: 0;
    } */

    .maCollection__row-nitro .maCollectionRow__left::after {
        border-color: #fb7f00;
        border-width: 2px;
        background-color: #ffffffde;
        height: calc(100% - 4px);
    }

    .maCollectionRow__left .maCollectionContent__link {
        z-index: 1;
    }

    .maCollectionRowRightTop__farmCount {
        flex-direction: column;
    }

    .maCollectionRowRightTop__farmCount:after {
        content: none;
    }

    .maCollectionRowRightTopFarmCount__elem-value {
        padding-left: 0;
    }

    .maCollectionRowRightTopFarmCount__elem-name {
        padding-right: 0;
    }

    .collectionWrap {
        margin-top: 40px;
    }

    .ma__collectionPage {
        margin-top: -20px;
    }

    .maCollectionRowRightTop__row-token .maCollectionRowRightTop__elem-value {
        max-width: 70px;
        font-size: 14px;
    }

    .burnBtn__wrap {
        margin-bottom: 30px;
    }

    .maMenu2__link-span {
        display: none;
    }

    .bountyCode__gift {
        top: 80px;
    }

    .bountyCode__gift-leftBg {
        display: none;
    }

    .bountyCode__gift-plus {
        top: -10px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .bountyCode__gift {
        width: 174px;
        border-top-left-radius: 43px;
        border-bottom-left-radius: 43px;
        top: 90px;
    }

    .bountyCode__gift-plus {
        display: none;
    }

    .nftFarmInformerContent__title {
        font-size: 18px;
    }

    .nftFarmInformerContent__text {
        margin: 20px;
        text-align: center;
    }

    .nftFarmInformer {
        background-color: #fdd200;
    }

    .maCollection__row-multiplyer .maCollectionRow__left::after {
        border-color: #fb7f00;
        border-width: 2px;
    }

    /* .maCollection__promotion-icons .maCollection__promotion:nth-child(1) {
        right: 10px;
    }

    .maCollection__promotion-icons .maCollection__promotion:nth-child(2) {
        top: 10px;
        right: -10px;
    }

    .maCollection__promotion-icons .maCollection__promotion:nth-child(3) {
        top: 50px;
    } */

    .maCollection__row-multiplyer .maCollectionRow__left::after {
        height: calc(100% - 4px);
    }

    /* .maDetailContentMain__right .maCollection__promotion-icons .maCollection__promotion:nth-child(1) {
        top: -80px;
        right: 60px;
    }

    .maDetailContentMain__right .maCollection__promotion-icons .maCollection__promotion:nth-child(2) {
        top: -80px;
        right: 30px;
    }

    .maDetailContentMain__right .maCollection__promotion-icons .maCollection__promotion:nth-child(3) {
        top: -80px;
        right: -15px;
    } */

    .maDetailCollection__row-combination-bg {
        font-size: 160px;
    }

    .detailPlusBlock__wrap {
        padding: 20px;
    }

    .detailPlusBlock__left {
        text-align: center;
        margin-right: 20px;
    }

    /* .maDetailContentMain__right .maCollection__promotion {
        top: -55px;
        right: -10px;
    }

    .maCollection__promotion:nth-child(2) {
        right: 35px;
        top: -55px;
    }

    .maCollection__promotion:nth-child(3) {
        top: -55px;
        right: 80px;
    } */

    .maDetailContent__btnWrap {
        flex-direction: column;
    }

    .farmInformer2Bottom__text {
        margin-left: 15px;
    }

    .farmInformer2__img {
        right: -160px;
    }

    .farmInformer2Bottom__text {
        letter-spacing: 3px;
    }

    .farmInformer2__title {
        padding: 20px 10px;
    }

    .farmInformer2__center {
        flex-direction: column;
    }

    .farmInformer2__bountyCode {
        margin: 20px 0;
    }

    .farmInformer2Bottom__right {
        padding-right: 20px;
        font-size: 14px;
    }

    .maMain__menuLink {
        margin-bottom: 0;
    }

    .maMain__menuWrap {
        gap: 20px;
    }

    .maCollection__row .maCollection__row-combination-bg {
        right: 30px;
        font-size: 50px;
    }

    .maDetailTop__titleWrap {
        margin-top: 30px;
    }

    .maDetailContentMain__info {
        box-sizing: border-box;
    }

    .maDetailContentBtnWrap__right {
        justify-content: center;
    }

    .maDetailContentMain__right {
        width: 100%;
    }

    .maLogo__textWrap {
        padding: 20px;
    }
}

@media(max-width: 1024px) {
    .bodyContent {
        margin: 0;
    }

    .maDetailContentMain__right {
        width: 100%;
    }

    .maCollection__row .maCollection__row-combination-bg {
        font-size: 160px;
    }

    .farmInformer2__left {
        text-align: center;
    }

    .farmInformer2__left br {
        display: none;
    }

    .maCollection__content {
        grid-template-columns: repeat(3, 1fr);
    }

    .ma__top {
        flex-direction: column-reverse;
    }

    .maTop__left {
        margin-top: 40px;
    }

    .maLogo__textWrap {
        padding: 18px 8px;
    }

    .ma__informer {
        box-sizing: border-box;
    }

    .maCollection__row {
        flex-direction: column;
    }

    .maCollectionRowRight__wrap {
        border-radius: 30px;
        border: 1px solid #fff;
        padding-top: 80px;
        margin-top: -60px;
        box-shadow: 1px -15px 0px 0px rgb(255 255 255 / 75%);
        -webkit-box-shadow: 1px -15px 0px 0px rgb(255 255 255 / 75%);
        -moz-box-shadow: 1px -15px 0px 0px rgba(255, 255, 255, 0.75);
    }

    .maCollection__row-multiplyer .maCollectionRowRight__wrap {
        border-color: #fb7f00;
        border-left: 1px solid #fb7f00;
        box-shadow: 1px -15px 0px 0px rgb(251 127 0 / 75%);
        -webkit-box-shadow: 1px -15px 0px 0px rgb(251 127 0 / 75%);
        -moz-box-shadow: 1px -15px 0px 0px rgba(251, 127, 0, 0.75);
    }

    .maCollectionRow__left {
        justify-content: center;
    }

    .maCollectionRowRightTopFarmCount__elem-value {
        margin-top: 2px;
    }

    .maCollectionRowRightTopFarmCount__elem-value .gico__logo {
        margin-left: 3px;
    }

    .maCollection__promotion {
        right: 0px;
        top: -89px;
    }

    .maCollection__content1 .maCollection__promotion:nth-child(2) {
        right: 45px;
        top: -89px;
    }

    .maCollectionRowRightBottom__right {
        justify-content: center;
    }

    .maCollection__row .maCollection__row-combination-bg {
        font-size: 40px;
        top: -40px;
        right: initial;
        bottom: initial;
        left: 20px;
    }
}

@media(max-width: 768px) {
    .ma__top {
        flex-direction: column;
    }

    .maTop__left {
        order: 2;
    }

    .maTop__center {
        order: 1;
    }

    .maTop__right {
        order: 3;
    }

    .ma__top:after {
        content: none;
    }

    .maMenu {
        margin: 34px 0 15px;
        position: relative;
        z-index: 1;
    }

    .maLogoTextWrap__title {
        font-size: 30px;
    }

    .maLogoTextWrap__subTitle {
        font-size: 14px;
    }

    .maTop__right {
        width: 100%;
        height: 22px;
        flex: initial;
    }

    .maMenu2 {
        height: 100%;
    }

    .maMenu2__link:first-of-type {
        border-right: 1px solid rgb(45 20 84 / 40%);
    }

    .maMenu2__link {
        font-size: 16px;
    }

    .maTop__left {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .maTop__left:after {
        content: "";
        clear: both;
        position: absolute;
        width: 100%;
        height: 48px;
        border-radius: 24px;
        background-color: #343f57;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 14px;
    }

    .maMenuLink__span {
        font-size: 12px;
    }

    .maMenuLink__span {
        top: -18px;
    }

    .maMain__menuWrap {
        display: none;
    }

    .maCollection__content {
        grid-template-columns: repeat(2, 1fr);
    }

    .maMain__currentMenuWrap-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
        padding-top: 20px;
        position: relative;
    }

    .maMain__currentMenuWrapMobile-color {
        display: flex;
        align-items: center;
        position: relative;
    }

    .maMain__currentMenuWrap-mobile:after {
        content: "";
        clear: both;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        width: calc(100% - 160px);
        height: 1px;
        background-color: rgb(45 20 84 / 40%);
    }

    .maMain__currentMenuWrapMobile-color .maBottomColor__text {
        opacity: 1;
    }

    .metaAristippus {
        padding-top: 100px;
        margin-top: 0;
    }

    .maDetailContent__btnWrap {
        flex-direction: column-reverse;
    }

    .burnBtn__wrap {
        margin-bottom: 0;
        margin-top: 30px;
    }

    .maDetailContentBtnWrap__left {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    .maDetailContentBtnWrap__right {
        flex-direction: column;
    }

    .maDetailContentBtnWrap__right .maDetailContent__btn {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .maDetailContentBtnWrap__right .maDetailContent__btn:last-of-type {
        margin-bottom: 0;
    }

    .maDetailContent__price {
        padding: 20px 10px;
    }

    .maDetailContentPrice__elem {
        font-size: 14px;
    }

    .maDetailContentPrice__value {
        font-size: 20px;
    }

    .bonus__logo {
        font-size: 11.5px;
    }

    .maDetailContentMain__left {
        width: 170px;
    }

    .maDetailContentMain__value {
        font-size: 18px;
        bottom: 11px;
    }

    .maDetailContentPrice__row-green::before {
        width: calc(100% + 17px);
        right: -10px;
    }

    .maDetailContentBtnWrap__checkbox {
        margin-left: 0;
        margin-top: 20px;
    }

    .maCollection__row {
        flex-direction: column;
    }

    .maCollectionRow__left::after {
        content: none;
    }

    .maCollectionRowRight__wrap {
        border-radius: 25px;
        border: 1px solid #cfcfcf;
        padding-top: 70px;
    }

    .maCollectionRowRightBottom__right {
        margin: 0 10px;
        justify-content: center;
        align-items: center;
    }

    .maCollection__content1 .maDetailContentBtnWrap__checkbox {
        margin-left: 0;
        margin-top: 10px;
    }

    .maCollectionRow__left {
        justify-content: center;
        margin-bottom: -60px;
    }

    .maCollectionRowRightBottom__left {
        text-align: center;
    }

    .maCollectionRowRight__top {
        flex-direction: column;
    }

    .maCollectionRowRightTop__right {
        margin-top: 20px;
    }

    .maCollectionRowRightTop__farmBlock {
        margin-left: 15px;
        margin-right: 15px;
        text-align: center;
        border-radius: 48px;
        padding: 20px;
    }

    .maCollectionRowRightTop__farmCountWrap {
        margin-left: 15px;
        margin-right: 15px;
        text-align: center;
        border-radius: 48px;
        padding: 20px;
        height: initial;
    }

    .collectionWrapRowElem__value {
        font-size: 24px;
    }

    .maPurchaseContent__img {
        max-width: 240px;
    }

    .apexcharts-canvas {
        width: 100%;
        overflow: hidden;
    }

    .maSuccessPayment__informer {
        padding: 20px;
    }

    .maSuccessPayment {
        padding: 50px 30px;
    }

    .maPayment__left,
    .maPayment__right {
        flex: 1;
        width: 50%;
    }

    .maMenu2__link {
        margin-right: 0;
        flex: 1;
    }

    .maMenu2__link:not(:last-of-type) {
        border-right: 1px solid rgb(45 20 84 / 40%);
    }

    .bountyCode__gift-leftBg {
        display: block;
        border-bottom: 84px solid #fdd400;
    }

    .bountyCode__gift-plus {
        display: flex;
        top: 50%;
        transform: translate(0, -50%);
        left: -29px;
    }

    .bountyCode__gift {
        top: 0;
        border: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 120px;
    }

    .maCollectionRowRightTop__farmBlock2 {
        text-align: left;
    }

    .freeBountyCode__plus {
        margin-right: 15px;
    }

    .freeBountyCode__text {
        margin-right: 15px;
    }

    .freeBountyCode__logo {
        font-size: 20px;
    }

    .nftFarmInformerContent__text {
        font-size: 16px;
    }

    .nftFarmInformerContent__title {
        font-size: 14px;
        height: 47px;
    }

    .nftFarmInformerContent__bounty {
        font-size: 16px;
    }

    .nftFarmInformerContent__special-text {
        font-size: 16px;
    }

    .nftFarmInformer__bgText {
        font-size: 90px;
    }

    .nftFarmInformerContent__text br {
        display: none;
    }

    .maCollection__row-multiplyer .maCollectionRowRight__wrap {
        border: 2px solid #fb7f00;
    }

    .detailPlusBlock__wrap {
        height: initial;
        flex-direction: column;
    }

    .detailPlusBlock__left {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .maPurchasePrice__ambLogo {
        max-width: 90px;
    }

    .farmInformer2__bountyCode {
        font-size: 26px;
    }

    .nftFarmInformerContent__special-figure2 {
        display: none;
    }

    .farmInformer2__bottom {
        flex-direction: column;
    }

    .farmInformer2Bottom__text {
        margin-left: 0;
        margin-bottom: 30px;
    }

    .farmInformer2__img {
        top: initial;
        bottom: -17px;
        right: -20px;
    }

    .farmInformer2Bottom__right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .maTop__left {
        margin-top: 0px;
    }

    .maTop__right {
        display: none;
    }

    .ma__main {
        padding-top: 0;
    }

    .metaAristippus .container {
        width: 100%;
    }

    .mainWrapper {
        padding-top: 0;
    }

    .maMain__text {
        font-size: 14px;
    }

    .maCollection__content1 {
        padding: 20px;
    }

    .ma__collection {
        margin-top: 0;
    }

    .ma__informer {
        padding: 25px;
        font-size: 14px;
        margin: 20px auto;
    }

    .maCollectionRowRight__wrap {
        padding-top: 140px;
    }

    .maCollectionRowRightBottom__right {
        flex-direction: column;
        margin-top: 20px;
    }

    .maCollection__row .maCollection__row-combination-bg {
        font-size: 30px;
    }

    .maDetailContentMain__imgWrap img {
        max-width: 100%;
    }

    .maDetailTop__titleWrap {
        padding-top: 1px;
    }

    .maDetailContentMainInfo__result-token {
        overflow-wrap: anywhere;
        font-size: 16px;
        line-height: 140%;
    }

    .maDetailCollection__row-combination-bg {
        font-size: 100px;
    }

    .maDetailContentPrice__row-green {
        margin-left: 20px;
    }
}

@media(max-width: 576px) {
    .ma__informer {
        padding: 25px 30px;
        width: calc(100% + 66px);
        margin-left: -33px;
        border-radius: 12px;
        text-align: left;
    }

    .maPayment {
        margin: 10px;
        flex-direction: column;
    }

    .maPayment__left,
    .maPayment__right {
        flex: 1;
        width: auto;
        margin: 5px;
    }

    .maBottomColor__elem {
        margin-right: 10px;
    }

    .maDetail__content {
        padding: 10px;
    }

    .maDetailContentMainInfo__row {
        flex-direction: column;
    }

    .maDetailContentMainInfo__row {
        margin-bottom: 10px;
    }

    .maDetailContentMain__info {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 80px;
    }

    /*  .maDetailContentPrice__row {
        flex-direction: column;
    } */

    .maDetailContentPrice__elem:last-of-type {
        text-align: right;
    }

    .maDetailContentPrice__row:not(:last-of-type) {
        margin-bottom: 10px;
    }

    .maDetailContentPrice__elem:last-of-type {
        position: relative;
    }

    .maDetailContentPrice__elem:last-of-type:after {
        content: "";
        clear: both;
        position: absolute;
        width: 100%;
        height: 1px;
        border-top: 1px dashed #2d1454;
        left: 0;
        bottom: 5px;
    }

    .maDetailContentPrice__value {
        position: relative;
        z-index: 1;
        background-color: #fff;
        padding-left: 5px;
        display: inline-block;
    }

    .maDetailTop__titleWrap {
        max-width: 230px;
        width: 100%;
        margin: 0 auto;
    }

    .maDetail__content {
        width: calc(100% + 56px);
        margin-left: -28px;
    }

    .maDetailTop__prev {
        left: -25px;
        top: -20px;
    }

    .maDetailTop__next {
        right: -25px;
        top: -25px;
    }

    .maDetailContentPrice__row-green .maDetailContentPrice__value {
        background-color: #212e48;
    }

    .maDetailContentPrice__row-green::before {
        height: 61px;
    }

    .ma__collectionPage:after {
        content: none !important;
    }

    .maCollection__content1 {
        padding: 20px 10px;
    }

    .collectionWrapRowElem__value-line {
        display: none;
    }

    .collectionWrapRow__elem-valueWrap {
        flex-direction: column;
        padding-left: 0;
        text-align: center;
    }

    .ma__main {
        padding: 20px 0px;
    }

    .maPurchaseAccord__content {
        padding: 20px 15px;
    }

    .maPurchaseContent__content {
        padding: 20px 10px;
    }

    .ma__backBtnWrap {
        margin: 0;
    }

    .ma__backBtn {
        font-size: 10px;
    }

    .maPurchaseAccordTop__text {
        font-size: 12px;
    }

    .ma__informer {
        width: 100%;
        margin: 20px auto;
    }

    .maBottomColor__text {
        font-size: 10px;
    }

    .maSuccessPayment {
        padding: 20px 10px;
    }

    .burnBtn__wrap {
        margin-bottom: 20px;
    }

    .nftFarmInformerContent__text {
        margin: 20px 10px;
        display: flex;
        flex-direction: column;
    }

    .nftFarmInformerContent__special-figure {
        width: 14px;
        margin-right: 20px;
    }

    .nftFarmInformerContent__special-text {
        margin-right: 10px;
    }

    .nftFarmInformer__bgText {
        font-size: 70px;
    }

    .maDetailContentMain__imgWrap {
        max-width: 170px;
        margin: 0 auto;
        width: 100%;
    }

    .maDetailContentMain__value {
        left: 50%;
        bottom: 14px;
        transform: translate(-50%, 0);
    }

    .maCollection__promotion:nth-child(3) {
        top: -105px;
        right: 0px;
    }

    .maCollection__promotion:nth-child(2) {
        right: 0px;
        top: -148px;
    }

    .maCollection__promotion:nth-child(1) {
        top: -191px;
        right: 0px;
    }

    .maCollection__row .maCollection__row-combination-bg {
        font-size: 90px;
    }

    .maCollection__content1 .maCollection__promotion:nth-child(1) {
        right: 0;
        top: -42px;
    }

    .maCollection__content1 .maCollection__promotion:nth-child(2) {
        right: 0;
        top: -84px;
    }

    .maCollection__content1 .maCollection__promotion:nth-child(3) {
        right: 0;
        top: -126px;
    }

    .paymentBlock .container {
        margin: 20px 0;
    }

    .paymentBill__h1 {
        font-size: 14px;
        text-align: center;
    }

    .sumBlock__wrapper-title {
        font-size: 12px;
        top: -35px;
    }

    .paymentDetails__paymentFlex {
        flex-direction: column;
        align-items: center;
    }

    .paymentDetails__paymentFlex span {
        padding-right: 0;
        margin-bottom: 5px;
        font-size: 12px;
    }

    .paymentDetails__paymentFlex span:last-of-type {
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: transparent;
        width: 160px;
        display: block;
        text-align: center;
        margin-right: 0;
    }

    .paymentDetails__paymentFlex:after {
        content: none;
    }

    .paymentDetails__paymentFlex:last-of-type {
        margin-bottom: 0;
        /* white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: transparent;
        width: 160px;
        display: block;
        text-align: center;
        margin-right: 0;
     */
    }

    .gosWallet__widget {
        border-radius: 5px;
        margin-top: 40px;
        padding: 30px 15px 20px;
    }

    .gosWalletWidget__header {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .gosYourBalance__title {
        text-align: center;
    }

    .gosYourBalance__wrap {
        justify-content: center;
    }

    .gosWalletWidget__wrap {
        padding: 15px;
        border-radius: 5px;
    }

    .gosWalletWidget__label {
        text-align: center;
        font-size: 14px;
    }

    .gosWalletWidget__input {
        font-size: 12px;
        padding: 0 10px;
    }

    .gosWalletWidget__inputWrap {
        margin-bottom: 10px;
    }

    .nftFarmInformerContent__title {
        padding: 10px;
    }

    .productBttn-360px {
        margin-top: 10px;
    }

    .farmInformer2__title {
        font-size: 16px;
    }

    .farmInformer2__left {
        font-size: 16px;
    }

    .farmInformer2__img {
        display: none;
    }

    .farmInformer2Bottom__right {
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-right: 0;
    }

    .farmInformer2Bottom__right {
        padding: 0 20px;
    }

    .maLogo__imgContent-img {
        max-width: 90px;
    }

    .maLogoTextWrap__title {
        font-size: 17px;
    }

    .maLogoTextWrap__subTitle {
        font-size: 10px;
        margin-top: 3px;
    }

    .maLogo__textWrap {
        padding: 10px 8px;
    }

    .maLogo__imgWrap {
        top: -90px;
    }

    .maMenu {
        display: flex;
        flex-wrap: wrap;
        gap: 30px 20px;
        justify-content: center;
        padding: 0;
        margin: 45px 0 15px;
    }

    .maMenu__link {
        margin-right: 0;
    }

    .maTop__left:after {
        height: calc(100% - 22px);
        bottom: initial;
        top: 15px;
        background-color: rgba(255, 255, 255, 0.2);
        width: calc(100% + 15px);
    }

    .maMain__textWrap {
        width: calc(100% - 20px);
    }

    .maMain__text {
        text-align: justify;
    }

    .collectionWrap {
        margin-top: 20px;
    }

    .maCollection__content1 {
        padding: 10px;
        width: calc(100% - 20px);
        box-sizing: border-box;
        margin: 0 auto;
    }

    .collectionWrap {
        width: calc(100% - 20px);
        margin: 20px auto;
    }

    .ma__collection:after {
        content: none !important;
    }

    .ma__collection {
        margin: 20px 0 !important;
    }

    .ma__informer {
        width: calc(100% - 20px);
        padding: 20px;
        text-align: justify;
    }

    .maCollectionRowRightTop__farmBlock {
        font-size: 10px;
        line-height: 140%;
        border-radius: 10px;
        margin: 0;
        width: 100%;
    }

    .ma__bottomColor {
        flex-wrap: wrap;
        gap: 20px;
    }

    .maBottomColor__elem {
        margin-right: 0;
    }

    .maCollectionContent__link {
        width: 110px;
        height: 164px;
    }

    .maMainMenuLink__number {
        font-size: 10px;
    }

    .maDetailTop__title {
        font-size: 20px;
    }

    .maCollectionRowRightTop__left {
        padding: 0;
    }

    .maCollectionRowRightTop__right {
        flex: initial;
    }

    .maCollectionRowRight__wrap {
        padding-left: 10px;
        padding-right: 10px;
    }

    .maCollectionRowRightTop__elem-name {
        font-size: 12px;
    }

    .maCollectionRowRightTop__row-token .maCollectionRowRightTop__elem-value {
        font-size: 12px;
    }

    .maCollectionRowRightTopFarmCount__elem-name {
        font-size: 12px;
    }

    .maCollectionRowRightTopFarmCount__elem-value {
        font-size: 12px;
        line-height: 140%;
    }

    .maCollectionRowRightTop__farmCountWrap {
        border-radius: 10px;
        margin: 0;
    }

    .maCollection__content1 .maDetailContent__btn {
        max-width: initial;
        font-size: 14px;
    }

    .maCollectionRowRightBottom__right {
        margin-left: 0;
        margin-right: 0;
    }

    .maCollection__content1 .switch-dot {
        width: 63px;
        right: 71px;
    }

    .switchDot__on {
        font-size: 20px;
    }

    .maCollection__content1 .switch {
        width: 144px;
    }

    .maCollection__content1 .switch-text:before {
        font-size: 10px;
    }

    .maCollection__content1 .switch-text:after {
        font-size: 10px;
    }

    .switchDotoText {
        font-size: 10px;
    }

    .switchDotoText2 {
        font-size: 10px;
    }

    .maCollection__promotion {
        width: 30px;
        height: 30px;
    }

    .maCollection__promotion img {
        max-width: 100%;
    }

    .maCollection__content1 .maCollection__promotion:nth-child(1) {
        right: -10px;
        top: -84px;
    }

    .maCollection__content1 .maCollection__promotion:nth-child(2) {
        right: -10px;
        top: -52px;
    }

    .maCollection__content1 .maCollection__promotion:nth-child(3) {
        right: -10px;
        top: -19px;
    }

    .maCollection__row .maCollection__row-combination-bg {
        font-size: 22px;
        left: -9px;
        transform: rotate(-90deg);
        color: rgba(255, 255, 255, 0.3);
        top: -20px;
        width: 50px;
        height: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .maCollectionContent__link .maMainMenuLink__hover {
        width: 104px;
        height: 104px;
    }

    .maMainMenuLinkHover__text {
        font-size: 11px;
    }

    .maDetailCollection__row-combination-bg {
        font-size: 70px !important;
    }

    .maDetailContentMainInfo__result {
        line-height: 140%;
        margin-top: 7px;
    }

    .maDetailContentPrice__elem {
        font-size: 11px;
    }

    .maDetailContentPrice__value {
        font-size: 15px;
    }

    .maDetailContentPrice__row-green::before {
        content: none;
    }

    .maDetailContentPrice__row-green .maDetailContentPrice__elem {
        background-color: #212e48;
    }

    .maDetailContentPrice__row-green {
        margin: 0;
    }

    .maDetailContentMain__imgWrap {
        max-width: 130px;
    }

    .maDetailContentMain__value {
        bottom: 12px;
        font-size: 11px;
    }

    .maDetailCollection__row-combination-bg {
        right: 20px;
        font-size: 50px !important;
    }

    .maDetail__top {
        margin: 0px 0 20px;
        padding-top: 10px;
    }

    .maDetailContentPrice__row-green {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .maDetailContentPrice__row:after {
        content: none;
    }

    .maDetailContentPrice__elem {
        padding: 0 !important;
    }

    .maDetailContentBtnWrap__checkbox {
        margin-top: 0;
    }

    .maDetail .switch {
        transform: scale(0.9);
    }
}

@media(max-width: 500px) {
    .maMain__currentMenuWrap-mobile:after {
        width: calc(100% - 60px);
    }

    .maCollection__content {
        padding: 0;
        background-color: initial;
    }

    .ma__collection {
        margin-top: 20px;
        /* width: calc(100% + 66px);
        margin-left: -41px; */
        position: relative;
    }

    .ma__collection:after {
        content: "";
        clear: both;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        height: calc(100% + 20px);
        background-color: #ebebf5;
        border-radius: 25px;
    }

    .maCollection__content {
        position: relative;
        z-index: 1;
        grid-template-columns: repeat(1, 1fr);
    }

    .maCollectionContent__link {
        margin: 0 auto;
    }

    .maDetailTop__next {
        right: -5px;
        width: 30px;
    }

    .maDetailTop__prev {
        left: 11px;
        width: 30px;
    }

    .maMenu2__link:first-of-type {
        border-right: none;
    }

    .maMenu2__link {
        text-align: center;
    }

    .purchaseForm {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .counter {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .notAmbassadorBtn__wrap .maPurchasePrice__row {
        flex-direction: column;
    }

    .notAmbassadorBtn__wrap .maPurchasePrice__row .maPurchasePrice__text {
        margin-bottom: 10px;
    }

    .maPurchasePrice__row-ambassador {
        flex-direction: column;
    }

    .maPurchasePrice__row-ambassador .maPurchasePrice__text {
        flex-direction: column;
        margin-bottom: 0 !important;
    }

    .maPurchasePrice__ambLogo {
        margin-left: 0;
        margin: 10px 0;
    }

    .notAmbassadorBtn__wrap {
        padding: 10px !important;
    }

    .maDetail .maDetail__content {
        width: calc(100% - 10px);
        box-sizing: border-box;
        margin: 0 auto;
    }

    .maDetailContentMainInfo__result {
        font-size: 12px !important;
    }

    .maDetailContentMainInfo__row:not(:last-of-type) {
        margin-bottom: 20px;
    }
}

@media(max-width: 374px) {
    .maCollection__content {
        grid-template-columns: 1fr;
    }

    .switch {
        width: 244px;
    }

    .switch-text:before {
        padding-left: 20px;
    }

    .switch-text:after {
        padding-right: 20px;
    }

    .switch-dot {
        width: 120px;
        right: 114px;
    }

    .bountyCode__gift-leftBg {
        display: none;
    }

    .bountyCode__gift-plus {
        display: none;
    }

    .maCollectionRowRightTop__farmBlock2 {
        font-size: 9px;
        width: 100%;
        padding-top: 30px;
    }

    .maCollectionRowRightTop__right {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .maDetailCollection__row-combination-bg {
        font-size: 160px;
    }
}