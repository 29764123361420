// @use '@styles/variables.scss';
@use 'containerModule';

$backgroundColorMain: #13131d;
$backgroundColorSecondary: #242435;

.coloredWrapper {
    // background: variables.$colorNoGradient;
    // background: variables.$colorGradient;
    min-height: 100vh;
}

.mainWrapper {
    padding: 2rem;
    border-radius: 1rem;
    background-color: $backgroundColorSecondary;

    @media only screen and (max-width: containerModule.$lg) {
        padding: 1.8rem;
    }

    @media only screen and (max-width: containerModule.$md) {
        padding: 1.6rem;
    }

    @media only screen and (max-width: containerModule.$sm) {
        padding: 1.4rem;
    }

    @media only screen and (max-width: containerModule.$xs) {
        padding: 1.2rem;
    }

    @media only screen and (max-width: containerModule.$xxs) {
        padding: 1rem;
    }

    .back_btn {
        font-size: 1.1rem;
        font-weight: bold;
        transition: 500ms all;
        display: block;
        cursor: pointer;
        color: #fff;
        text-decoration: none;
        margin-bottom: 1.8rem;
        width: fit-content;
        position: relative;

        @media (hover: hover) {
            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                width: 0;
                height: 0.05rem;
                bottom: -0.3rem;
                left: 0;
                background-color: white;
                transition: width 0.25s ease-out;
            }

            &:hover {
                &:after {
                    width: 100%;
                }
            }
        }

        @media only screen and (max-width: containerModule.$lg) {
            margin-bottom: 1.6rem;
            font-size: 1.05rem;
        }

        @media only screen and (max-width: containerModule.$md) {
            margin-bottom: 1.4rem;
            font-size: 1rem;
        }

        @media only screen and (max-width: containerModule.$sm) {
            margin-bottom: 1.2rem;
            font-size: 0.9rem;
        }

        @media only screen and (max-width: containerModule.$xs) {
            margin-bottom: 1rem;
            font-size: 0.85rem;
        }

        @media only screen and (max-width: containerModule.$xxs) {
            margin-bottom: 1rem;
            font-size: 0.8rem;
        }
    }
}

.defaultContainer {
    min-width: 360px;
    min-height: 100vh;
    padding-bottom: 2.5rem;
    background-color: $backgroundColorMain;
}

.defaultWrapper {
    background-color: $backgroundColorMain;
    min-height: 100vh;
    // color: variables.$colorBlack;

    &.wrapper-DAO {
        background-image: -webkit-linear-gradient(123deg, rgb(168, 21, 27) 0%, rgb(21, 39, 62) 100%);
        // color: variables.$colorWhite;
    }

    &.wrapper-ROYALTY {
        background-image: -webkit-linear-gradient(123deg, rgb(28, 112, 142) 0%, rgb(52, 7, 86) 100%);
        // color: variables.$colorWhite;
    }

    &.wrapper-AMBASSADOR {
        background-image: -webkit-linear-gradient(123deg, rgb(28, 105, 193) 0%, rgb(48, 0, 45) 100%);
        // color: variables.$colorWhite;
    }

    &.wrapper-METAVERSE {
        background-image: -webkit-linear-gradient(123deg, rgb(28, 105, 193) 0%, rgb(48, 0, 45) 100%);
        // color: variables.$colorWhite;
    }
}

.container {
    max-width: 1200px;
    width: calc(100% - 50px);
    margin: 0 auto;
}

.convertDao {
    background-color: #13131d;
    border-radius: 6px;
    padding: 50px;
    position: relative;

    .daoConvertElemRow__amount {
        padding: 0 10px;
        border: none;
    }

    .daoConvertElemRow__amount:focus {
        outline: none;
    }

}

.convertDao__lvl {
    display: flex;
    gap: 30px;
}

.convertDao__lvl-elem {
    flex: 1;
    padding: 30px;
    filter: grayscale(1);
    opacity: 0.4;
    border: 1px solid #fff;
}

.convertDaoLvl__value {
    font-size: 60px;
    text-align: center;
}

.convertDao__lvl-elem.level-active {
    filter: initial;
    opacity: 1;
    border: 1px solid #005281;
}

.errorMsg {
    color: #c90000;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    margin-top: 5px;
}

@media(max-width: 1024px) {
    .convertDao {
        padding: 20px;
    }

    .convertDaoLvl__value {
        font-size: 40px;
    }

    .convertDao__lvl-elem {
        padding: 20px;
    }

    .daoConvertElemRow__amount {
        box-sizing: border-box;
    }

    .errorMsg {
        font-size: 10px;
    }

}

@media(max-width: 768px) {
    .convertDao__lvl {
        gap: 10px;
    }

    .daoConvert {
        .gico-balance {
            font-size: 16px;
        }
    }
}

@media(max-width: 576px) {
    .convertDaoLvl__value {
        font-size: 30px;
    }

    .convertDaoLvl__title {
        font-size: 13px;
    }

    .convertDao__lvl {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .convertDao__lvl-elem {
        width: 100%;
        box-sizing: border-box;
        margin: 0 !important;
    }

    .daoConvert {
        .gico-balance {
            font-size: 14px;
            text-align: center;
        }
    }
}