@use '@styles/variables.scss';
// @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&display=swap');

body {
    padding: 0 !important;
}

.title {
    // color: variables.$colorBlack;
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: bold;
}

.subtitle {
    // color: variables.$colorBlack;
    font-size: 22px;
    margin-bottom: 10px;
}

.titleSvg{
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .title{
        margin-bottom: 0;
    }

    svg{
        margin-left: 10px;
    }
}

.new-line {
    white-space: pre-line;
}

div:where(.swal2-icon) {
    position: relative;
    box-sizing: content-box;
    justify-content: center;
    width: 5em;
    height: 5em;
    margin: 2.5em auto 0.6em;
    border: 0.25em solid rgba(0,0,0,0);
    border-radius: 50%;
    border-color: #000;
    font-family: inherit;
    line-height: 5em;
    cursor: default;
    user-select: none;
}