@use 'volumeModule';

.volume_container {

    .content_wrapper {
        width: 100%;
        border-radius: 1rem;
        background-repeat: no-repeat;
        background-attachment: fixed;
        min-height: 100vh;
        background-size: cover;
        background-position-x: center;
        padding: 0 1rem;
        box-sizing: border-box;

        .token_wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 3rem;

            @media only screen and (max-width: volumeModule.$sm) {
                margin-top: 2rem;
            }

            .preloader_wrapper {
                width: 5rem;
                height: 5rem;
            }
        }
    }
}