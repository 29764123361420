.nft__detail {
    border-radius: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px;
    position: relative;
    margin-bottom: 50px;
}


.back__btn {
    position: absolute;
    top: -30px;
    left: 10px;
    font-size: 16px;
    font-weight: bold;
    transition: 500ms all;
    display: inline-block;
    cursor: pointer;
    color: #fff;
    text-decoration: none;

    &:hover {
        transform: scale(1.1);
        text-decoration: underline;
        text-underline-offset: 3px;
    }
}

.nft__content {
    position: relative;
    border-radius: 20px;
}

.defaultWrapper {
    padding-bottom: 50px;
}

.nftCollectionsDetail .nftCollectionsDetail__imageWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

$stage: 4;

@for $i from 1 through $stage {
    .wrapper-nextera-stage-#{$i} {
        .nftCollectionsDetail__tokenId {
            color: rgb(255 255 255 / 50%) !important;
            width: calc(100% - 20px);
            text-align: center;
            top: 120px;
        }
    }
}

.wrapper-the-big-day-2023 {
    .nftCollectionsDetail__tokenId {
        color: #5896ab !important;
        width: calc(100% - 20px);
        text-align: center;
        bottom: 70px !important;
    }
}

@media(max-width: 1200px) {
    .nftCollectionsDetail {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(max-width: 1024px) {
    .nft__detail {
        padding: 30px;
    }

    .nftCollectionsDetail {
        grid-gap: 30px !important;
    }
}

@media(max-width: 768px) {
    .nftCollectionsDetail .nftCollectionsDetail__imageWrap .nftCollectionsDetail__image {
        width: 200px;
    }

    .nftCollectionsDetail__tokenId {
        bottom: 20px;
    }

    $stage: 4;

    @for $i from 1 through $stage {
        .wrapper-nextera-stage-#{$i} {
            .nftCollectionsDetail__tokenId {
                color: rgb(255 255 255 / 50%) !important;
                width: calc(100% - 20px);
                text-align: center;
                top: 80px !important;
            }
        }
    }

    .wrapper-the-big-day-2023 {
        .nftCollectionsDetail__tokenId {
            color: #5896ab !important;
            width: calc(100% - 20px);
            text-align: center;
            bottom: 45px !important;
        }
    }

    .wrapper-custody {
        .nftCollectionsDetail__tokenId {
            color: #5896ab !important;
            width: calc(100% - 20px);
            text-align: center;
            bottom: 15px !important;
        }
    }

    $glory: 3;

    @for $i from 1 through $glory {
        .wrapper-glory-blaze-#{$i} {
            .nftCollectionsDetail__tokenId {
                color: #88aebb !important;
                width: calc(100% - 20px);
                text-align: center;
                bottom: 10px !important;
            }
        }
    }

    .nft__detail {
        padding: 30px !important;
    }

    .nftCollectionsDetail__tokenId {
        width: 100% !important;
        text-align: center !important;
        bottom: 6px !important;
        font-size: 11px !important;
    }
}

@media(max-width: 576px) {

    .nft__content {
        margin-top: 40px;
    }

    .nftCollectionsDetail {
        grid-template-columns: 1fr;
    }

    .title {
        font-size: 24px;
        line-height: 140%;
    }
}

.wrapper-patron {
    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 50%) !important;
        width: calc(100% - 20px);
        text-align: center;
    }

    .nftCollectionsDetail {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 50px !important;
    }
}

.nft__detailWrap {
    border-radius: 20px;
    overflow: hidden;
}

.wrapper-ambassador {
    .nft__detail {
        border-radius: 20px;
        background-image: -moz-linear-gradient(123deg, rgb(28, 105, 193) 0%, rgb(48, 0, 45) 100%);
        background-image: -webkit-linear-gradient(123deg, rgb(28, 105, 193) 0%, rgb(48, 0, 45) 100%);
        background-image: -ms-linear-gradient(123deg, rgb(28, 105, 193) 0%, rgb(48, 0, 45) 100%);
        background-image: linear-gradient(123deg, rgb(28, 105, 193) 0%, rgb(48, 0, 45) 100%);
        padding: 70px;
        position: relative;
        margin-bottom: 50px;
    }

    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 50%) !important;
        width: calc(100% - 20px);
        text-align: center;
    }
}

.wrapper-royalty {
    .nft__detail {
        border-radius: 20px;
        background-image: linear-gradient(123deg, rgb(28, 112, 142) 0%, rgb(52, 7, 86) 100%);
        background-image: -moz-linear-gradient(123deg, rgb(28, 112, 142) 0%, rgb(52, 7, 86) 100%);
        background-image: -webkit-linear-gradient(123deg, rgb(28, 112, 142) 0%, rgb(52, 7, 86) 100%);
        background-image: -ms-linear-gradient(123deg, rgb(28, 112, 142) 0%, rgb(52, 7, 86) 100%);
        padding: 70px;
        position: relative;
        margin-bottom: 50px;
    }

    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 50%) !important;
        width: calc(100% - 20px);
        text-align: center;
    }
}

.wrapper-dao {
    .nft__detail {
        border-radius: 20px;
        background-image: -moz-linear-gradient(123deg, rgb(168, 21, 27) 0%, rgb(21, 39, 62) 100%);
        background-image: -webkit-linear-gradient(123deg, rgb(168, 21, 27) 0%, rgb(21, 39, 62) 100%);
        background-image: -ms-linear-gradient(123deg, rgb(168, 21, 27) 0%, rgb(21, 39, 62) 100%);
        background-image: linear-gradient(123deg, rgb(168, 21, 27) 0%, rgb(21, 39, 62) 100%);
        padding: 70px;
        position: relative;
        margin-bottom: 50px;
    }

    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 50%) !important;
        width: calc(100% - 20px);
        text-align: center;
    }
}

.wrapper-dinner-guest {
    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 50%) !important;
        width: calc(100% - 20px);
        text-align: center;
    }
}

.wrapper-dinner-winner {
    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 50%) !important;
        width: calc(100% - 20px);
        text-align: center;
    }
}

.wrapper-reliable {
    .nft__detail {
        border-radius: 20px;
        background-image: -moz-linear-gradient(135deg, rgb(27, 38, 32) 0%, rgb(0, 43, 94) 100%);
        background-image: -webkit-linear-gradient(135deg, rgb(27, 38, 32) 0%, rgb(0, 43, 94) 100%);
        background-image: -ms-linear-gradient(135deg, rgb(27, 38, 32) 0%, rgb(0, 43, 94) 100%);
        background-image: linear-gradient(135deg, rgb(27, 38, 32) 0%, rgb(0, 43, 94) 100%);
        padding: 70px;
        position: relative;
        margin-bottom: 50px;
        overflow: hidden;
    }

    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 50%) !important;
        width: calc(100% - 20px);
        text-align: center;
    }
}

.wrapper-gold-reliable {
    .nft__detail {
        border-radius: 20px;
        background-image: -moz-linear-gradient(135deg, rgb(33, 42, 37) 0%, rgb(32, 62, 61) 100%);
        background-image: -webkit-linear-gradient(135deg, rgb(33, 42, 37) 0%, rgb(32, 62, 61) 100%);
        background-image: -ms-linear-gradient(135deg, rgb(33, 42, 37) 0%, rgb(32, 62, 61) 100%);
        background-image: linear-gradient(135deg, rgb(33, 42, 37) 0%, rgb(32, 62, 61) 100%);
        padding: 70px;
        position: relative;
        margin-bottom: 50px;
        overflow: hidden;
    }

    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 40%) !important;
        width: calc(100% - 20px);
        text-align: center;
    }
}

.wrapper-unlimited-reliable {
    .nft__detail {
        border-radius: 20px;
        background-image: -moz-linear-gradient(135deg, rgb(38, 8, 67) 0%, rgb(43, 33, 125) 100%);
        background-image: -webkit-linear-gradient(135deg, rgb(38, 8, 67) 0%, rgb(43, 33, 125) 100%);
        background-image: -ms-linear-gradient(135deg, rgb(38, 8, 67) 0%, rgb(43, 33, 125) 100%);
        background-image: linear-gradient(135deg, rgb(38, 8, 67) 0%, rgb(43, 33, 125) 100%);
        padding: 70px;
        position: relative;
        margin-bottom: 50px;
        overflow: hidden;
    }

    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 40%) !important;
        width: calc(100% - 20px);
        text-align: center;
    }
}

.wrapper-supreme {
    .nft__detail {
        border-radius: 20px;
        background-image: -moz-linear-gradient(135deg, #060b31 0%, #fe86ff 100%);
        background-image: -webkit-linear-gradient(135deg, #060b31 0%, #fe86ff 100%);
        background-image: -ms-linear-gradient(135deg, #060b31 0%, #fe86ff 100%);
        background-image: linear-gradient(135deg, #060b31 0%, #fe86ff 100%);
        padding: 70px;
        position: relative;
        margin-bottom: 50px;
        overflow: hidden;
    }

    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 40%) !important;
        width: calc(100% - 20px);
        text-align: center;
    }
}

.wrapper-confidence {
    .nft__detail {
        border-radius: 20px;
        background-image: -moz-linear-gradient(135deg, #b7b8aa 0%, #6d7b7b 100%);
        background-image: -webkit-linear-gradient(135deg, #b7b8aa 0%, #6d7b7b 100%);
        background-image: -ms-linear-gradient(135deg, #b7b8aa 0%, #6d7b7b 100%);
        background-image: linear-gradient(135deg, #b7b8aa 0%, #6d7b7b 100%);
        padding: 70px;
        position: relative;
        margin-bottom: 50px;
        overflow: hidden;
    }

    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 40%) !important;
        width: calc(100% - 20px);
        text-align: center;
    }
}

.wrapper-impulse {
    .nft__detail {
        border-radius: 20px;
        background-image: -moz-linear-gradient(135deg, #944829 0%, #34292d 100%);
        background-image: -webkit-linear-gradient(135deg, #944829 0%, #34292d 100%);
        background-image: -ms-linear-gradient(135deg, #944829 0%, #34292d 100%);
        background-image: linear-gradient(135deg, #944829 0%, #34292d 100%);
        padding: 70px;
        position: relative;
        margin-bottom: 50px;
        overflow: hidden;
    }

    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 40%) !important;
        width: calc(100% - 20px);
        text-align: center;
    }
}


.wrapper-dao-cup-stage-1 {
    .nft__detail {
        background-image: -moz-linear-gradient(135deg, rgb(19, 25, 26) 0%, rgb(79, 99, 107) 100%);
        background-image: -webkit-linear-gradient(135deg, #13191a 0%, rgb(79, 99, 107) 100%);
        background-image: -ms-linear-gradient(135deg, rgb(19, 25, 26) 0%, rgb(79, 99, 107) 100%);
        background-image: linear-gradient(135deg, rgb(19, 25, 26) 0%, rgb(79, 99, 107) 100%);
    }

    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 30%) !important;
        bottom: 10px !important;
    }
}

.wrapper-dao-cup-stage-2 {
    .nft__detail {
        background-image: -moz-linear-gradient(135deg, rgb(19, 25, 26) 0%, rgb(79, 99, 107) 100%);
        background-image: -webkit-linear-gradient(135deg, #13191a 0%, rgb(79, 99, 107) 100%);
        background-image: -ms-linear-gradient(135deg, rgb(19, 25, 26) 0%, rgb(79, 99, 107) 100%);
        background-image: linear-gradient(135deg, rgb(19, 25, 26) 0%, rgb(79, 99, 107) 100%);
    }

    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 30%) !important;
        bottom: 10px !important;
    }
}

.wrapper-dao-cup-stage-3 {
    .nft__detail {
        background-image: -moz-linear-gradient(135deg, rgb(19, 25, 26) 0%, rgb(79, 99, 107) 100%);
        background-image: -webkit-linear-gradient(135deg, #13191a 0%, rgb(79, 99, 107) 100%);
        background-image: -ms-linear-gradient(135deg, rgb(19, 25, 26) 0%, rgb(79, 99, 107) 100%);
        background-image: linear-gradient(135deg, rgb(19, 25, 26) 0%, rgb(79, 99, 107) 100%);
    }

    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 30%) !important;
        bottom: 10px !important;
    }
}

.wrapper-dao-cup-stage-4 {
    .nft__detail {
        background-image: -moz-linear-gradient(135deg, rgb(19, 25, 26) 0%, rgb(79, 99, 107) 100%);
        background-image: -webkit-linear-gradient(135deg, #13191a 0%, rgb(79, 99, 107) 100%);
        background-image: -ms-linear-gradient(135deg, rgb(19, 25, 26) 0%, rgb(79, 99, 107) 100%);
        background-image: linear-gradient(135deg, rgb(19, 25, 26) 0%, rgb(79, 99, 107) 100%);
    }

    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 30%) !important;
        bottom: 10px !important;
    }
}

.wrapper-credence {

    .nftCollectionsDetail__tokenId {
        color: #fff !important;
        bottom: 10px !important;
    }
}

.wrapper-nextera-winner {
    .nftCollectionsDetail__tokenId {
        bottom: 300px;
    }
}

.wrapper-nextera-participant {
    .nftCollectionsDetail__tokenId {
        bottom: 307px;
        color: #a9a9a9;
        font-size: 19px;
    }
}

.wrapper-reliable {
    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 40%) !important;
    }
}

.wrapper-gold-reliable {
    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 40%) !important;
    }
}

.wrapper-unlimited-reliable {
    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 40%) !important;
    }
}

.wrapper-reliable-2 {
    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 40%) !important;
    }
}

.wrapper-gold-reliable-2 {
    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 40%) !important;
    }
}

.wrapper-unlimited-reliable-2 {
    .nftCollectionsDetail__tokenId {
        color: rgb(255 255 255 / 40%) !important;
    }
}

.wrapper-nextera-winner {
    .nftCollectionsDetail__tokenId {
        top: 29%;
        color: #a89cca !important;
    }
}

.wrapper-nextera-participant {
    .nftCollectionsDetail__tokenId {
        bottom: 10px !important;
        color: #545d74 !important;
    }
}

.grda__logo {
    font-family: inherit !important;
}

.wrapper-nft-ia-app .nft__detail {
    background-color: #91c4f2;
}

.wrapper-nft-ia-app .nftCollectionsDetail__tokenId {
    top: 10%;
}

.wrapper-unity-globe-stage-1 .nftCollectionsDetail__tokenId {
    color: #d6d6e7 !important;
}

.wrapper-unity-globe-stage-2 .nftCollectionsDetail__tokenId {
    color: #d6d6e7 !important;
}

.wrapper-unity-globe-stage-3 .nftCollectionsDetail__tokenId {
    color: #d6d6e7 !important;
}

.wrapper-unity-globe-stage-4 .nftCollectionsDetail__tokenId {
    color: #d6d6e7 !important;
}

.wrapper-for-the-development-of-metaverse .nftCollectionsDetail__tokenId {
    top: 10%;
    color: #e5c0fd !important;
}

.wrapper-unity-globe-winner .nftCollectionsDetail__tokenId {
    color: #d6d6e7 !important;
}

.wrapper-unity-globe-winner .nftCollectionsDetail__tokenId {
    color: #d6d6e7 !important;
}