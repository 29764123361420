@use 'metaSentryModule';

@mixin sentryBorder($borderColor: var(--main-intensive-blue)) {
    border-radius: 10rem 2rem;
    border: solid 0.4rem $borderColor;
}

.meta_container {
    @include sentryBorder($borderColor: transparent);
    width: 100%;
    border-radius: 10rem 2rem;
    box-sizing: border-box;

    .mainWrapper {
        @include sentryBorder($borderColor: transparent);
        padding: 0;
        margin: 0;

        .yourMetasentry__title {
            margin: 0;
            text-align: center;
        }
    }
}

.yourMetasentry__title {
    color: #fff;
    font-size: 22px;
    font-weight: 900;
    margin-left: 72px;
}

.yourMetasentry__content {
    @include sentryBorder;
    background-color: var(--main-intensive-blue);
    padding: 3rem 1rem;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 30px;
}

.yourMetasentry__elem {
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
    width: fit-content;
}

// .kKIuGD {
//     width: fit-content;
//     height: fit-content;
// }

.yourMetasentry__img {
    height: 363px;
    object-fit: contain;
    width: 100%;
}

.yourMetasentry__imgWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.yourMetasentry__btnWrap {
    margin-top: 25px;
}

.yourMetasentry__elem {
    position: relative;
}

.yourMetasentry__tokenId {
    width: 100%;
    text-align: center;
    color: #fff;
    line-height: 120%;
    font-weight: 500;
}

.yourMetasentry__tokenId span {
    font-weight: 900;
}

.yourMetasentry__activated {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.yourMetasentry__activated svg {
    width: 40px;
    height: 40px;
}

.yourMetasentry__activated path {
    fill: metaSentryModule.$primary-green !important;
}

.yourMetasentry__imgWrap {
    position: relative;
    border: 4px solid #212e48;
    border-radius: 19px;
}

.yourMetasentry__imgWrap-border {
    border-color: metaSentryModule.$primary-green;
}

@media(max-width: 1200px) {
    .yourMetasentry__content {
        border-radius: 8rem 1rem;
        padding: 3.5rem 1rem;
    }

    .yourMetasentry__tokenId {
        font-size: 15px;
    }

    .yourMetasentry__img {
        height: 300px;
    }

    .yourMetasentry {
        padding-bottom: 50px;
    }

    .yourMetasentry__title {
        margin-left: 20px;
    }

}

@media(max-width: 992px) {

    .yourMetasentry__content {
        border-radius: 6rem 1rem;
        padding: 3.5rem 0.2rem;
    }

    .yourMetasentry__tokenId {
        font-size: 14px;
    }

    .yourMetasentry__img {
        height: 270px;
    }
}

@media(max-width: 768px) {
    .yourMetasentry__title {
        margin-left: 0;
        text-align: center;
        font-size: 18px;
        padding-top: 30px;
    }

    .yourMetasentry__content {
        grid-template-columns: repeat(2, 1fr);
        border-radius: 4rem;
        padding: 4rem 0.5rem;
        row-gap: 2rem;
        margin-top: 20px;
    }

    .yourMetasentry__tokenId {
        font-size: 15px;
    }

    .yourMetasentry__img {
        height: 270px;
    }

    .yourMetasentry__title {
        font-size: 20px;
    }

    .yourMetasentry__btnWrap {
        margin-top: 15px;
    }
}

@media(max-width: 576px) {
    .yourMetasentry {
        padding-bottom: 15px;
    }

    .yourMetasentry__tokenId {
        font-size: 16px;
    }

    .yourMetasentry__activated {
        top: -15px;
        right: -15px;
        width: 30px;
        height: 30px;
    }

    .yourMetasentry__imgWrap {
        border-width: 3px;
        border-radius: 12px;
    }

    .yourMetasentry__content {
        grid-template-columns: repeat(1, 1fr);
        border-radius: 2rem;
    }

    .yourMetasentry__tokenId {
        font-size: 15px;
    }

    .yourMetasentry__img {
        height: 300px;
    }
}