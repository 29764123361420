@use '@styles/variables.scss';

.cardWrap{
    position: relative;

    .commingSoonBg{

        cursor: no-drop;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        &::after{
            content: 'Coming soon';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #000;
            font-size: 25px;
            font-weight: bold;
        }
    }
}

.card{
    margin: 10px 15px;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;
    min-height: 200px;
    cursor: pointer;
    transition: .5s;
    padding: 10px 25px;
    
    background: variables.$colorNoGradient;
    background: variables.$colorGradient;

    &.commingSoon{

        position: relative;
        // filter: grayscale(1);
        filter: blur(2px) grayscale(1);

        // &::after{
        //     content: 'Coming soon';
        //     position: absolute;
            
        // }
    }

    .card__title{
        color: variables.$colorWhite;
        font-size: 25px;
        margin-bottom: 30px;
    }
    .card__content{
        color: variables.$colorWhite;
        font-size: 16px;
        text-align: center;
        line-height: 25px;
    }
    &:hover{
        background: variables.$colorNoGradient;
        background: variables.$colorGradient;
    }
}

.cardHover{

    .carhHover__front{
        display: block;
        // display: none;
    }
    .carhHover__back{
        display: none;
        // display: block;
        width: 100%;
    }

    .carhHover__back-item{
        color: variables.$colorWhite;
        padding: 10px 10px;
        border-radius: 8px;
    
        .carhHover__back-icon{
            width: 20px;
            height: 20px;
        }
    
        .carhHover__back-itemText{
            padding-left: 10px;
        }
    
        &:hover{
            background: #ffffff1f;
        }
    }

    &:hover{
        .carhHover__front{
            display: none;
        }
        .carhHover__back{
            display: block;
        }   
    }
}