$dark-blue: #061a40;
$deep-blue: #04122dcc;
$bloodyRed: #ba0000;

.wisdom_container {
    width: 100%;
    border-radius: 1rem;
    border: solid 0.5rem var(--main-faded-blue);
    padding: 1rem 1rem 2rem;
    box-sizing: border-box;
    background-color: $dark-blue;

    .maDetailTop__titleWrap {
        margin-top: 3rem;

        @media(max-width: 768px) {
            margin-top: 2rem;
        }

        @media(max-width: 576px) {
            margin-top: 0;
        }
    } 

    .maDetailContentMain__value {
        color: $bloodyRed;

        @media(max-width: 768px) {
            font-size: 16px;
        }

        @media(max-width: 576px) {
            font-size: 12px;
        }
    }

    .maMainMenuLink__hover {
        width: 140px;
        height: 140px;
        top: 110px;
    }

    .maCollectionContent__link {

        .maMainMenuLink__hover {
            width: 150px;
            height: 150px;
            top: 125px;
        }
    }

    .maMainMenuLinkHover__text {
        font-size: 15px;
    }

    .maLogo__imgWrap {
        background-color: var(--main-faded-blue);
    }

    .maLogo__imgContent {
        background-color: $dark-blue;
    }

    .maLogo__textWrap {
        background-color: $deep-blue;
    }

    .ma__top:after {
        background-color: $deep-blue;

        @media(max-width: 1024px) {
            bottom: 5px;
        }

        @media(max-width: 768px) {
            bottom: 8px;
        }
    }

    .maTop__left:after {
        background-color: $deep-blue;

        @media(max-width: 1024px) {
            bottom: 100px;
        }

        @media(max-width: 768px) {
            bottom: 8px;
        }
    }

    .maMainMenuLink__number {
        left: calc(50% - 4rem);
        bottom: 1rem;
    }

    .maCollection__content {
        grid-template-columns: repeat(4, 1fr);

        @media(max-width: 1200px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media(max-width: 1024px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .maCollectionRow__left {

        .maMainMenuLink__hover {
            width: 110px;
            height: 110px;
            top: 85px;

            .maMainMenuLinkHover__text {
                font-size: 14px;
            }

            @media(max-width: 576px) {
                width: 85px;
                height: 85px;
                top: 70px;

                .maMainMenuLinkHover__arrow {
                    width: 30px;
                    height: 30px;
                }

                .maMainMenuLinkHover__text {
                    font-size: 12px;
                }
            }
        }

        .maMainMenuLink__number {
            left: calc(50% - 2.7rem);
            bottom: 0.6rem;
            font-size: 14px;

            @media(max-width: 576px) {
                left: calc(50% - 2.1rem);
                bottom: 0.4rem;
                font-size: 12px;
            }
        }
    }

    .cardWrapper {
        width: 100%;
        height: 300px;

        @media(max-width: 576px) {
            height: 200px;
        }

        .maCollectionContent__link {
            width: 100%;
            height: 100%;
        }

        .maCollectionContent__link-active {
            width: 100%;
            height: 100%;
        }

        .maMainMenuLink__number {
            left: calc(50% - 4.5rem);

            @media(max-width: 576px) {
                left: calc(50% - 3rem);
                bottom: 10px;
                font-size: 13px;
            }
        }

        @media(max-width: 576px) {

            .maMainMenuLink__hover {
                width: 105px;
                height: 105px;
                top: 85px;

                .maMainMenuLinkHover__arrow {
                    width: 30px;
                    height: 30px;
                }

                .maMainMenuLinkHover__text {
                    font-size: 12px;
                }
            }
        }
    }

    .maMain__textWrap {
        width: 100%;
        text-align: center;
        margin: 0;
        margin-top: 1rem;
        max-width: unset;
    }

    .maCollectionContentLink__bg {
        background-position: center;
    }

    .maCollection__content1 {
        background-color: $deep-blue;
    }

    .collectionWrap {
        background: unset;
        border: unset;

        .maCollection__content1 {
            row-gap: 1rem;
        }
    }

    .ma__informer {
        background-color: var(--main-strong-blue);
    }
}

.series2Nft {
    .ma__content {
        background-color: #061a40;
    }

    .maMenu2__link {
        color: #000000;
    }

    .maMain__menuLink-green .maMain__menuLink__bg {
        /* background: url(/images/aristippus_wisdom/menu/MAW-G-Aristippus.png); */
        background-repeat: no-repeat;
        background-position: left top;
    }

    .maMain__menuLink-yellow .maMain__menuLink__bg {
        // background: url(/images/aristippus_wisdom/menu/MAW-Y-Aristippus.png);
        background-repeat: no-repeat;
        background-position: left top;
    }

    .maMain__menuLink-red .maMain__menuLink__bg {
        // background: url(/images/aristippus_wisdom/menu/MAW-R-Aristippus.png);
        background-repeat: no-repeat;
        background-position: left top;
    }

    .maMain__menuLink-blue .maMain__menuLink__bg {
        // background: url(/images/aristippus_wisdom/menu/MAW-B-Aristippus.png);
        background-repeat: no-repeat;
        background-position: left top;
    }

    .maMain__menuLink-purple .maMain__menuLink__bg {
        // background: url(/images/aristippus_wisdom/menu/MAW-P-Aristippus.png);
        background-repeat: no-repeat;
        background-position: left top;
    }

    .maMainMenuLink__number {
        /* color: #424242;
        font-size: 22px;
        font-weight: 300; */
        bottom: 20px;
        left: 25px;
        transform: initial;
        text-align: left;
    }

    .maMain__menuLink .maMainMenuLink__number {
        font-size: 16px !important;
        bottom: 20px !important;
        left: 25px !important;
    }

    /* .maMainMenuLink__number-red {
        color: #d10000;
        font-weight: 500;
    } */

    .maMain__menuLink:hover .maMain__menuLink__bg {
        filter: none;
    }

    .series-color-purple .maCollectionRow__right:after {
        background-color: #52448b;
    }

    .series-color-green .maCollectionRow__right:after {
        background-color: #156c25;
    }

    .series-color-yellow .maCollectionRow__right:after {
        background-color: #846825;
    }

    .series-color-red .maCollectionRow__right:after {
        background-color: #6b1414;
    }

    .series-color-blue .maCollectionRow__right:after {
        background-color: #1b3672;
    }


    .ma__collectionPage .maCollection__content1 {
        background-color: #04122d;
    }

    .series-color-yellow .maMainMenuLinkHover__arrow {
        background-color: rgb(255 206 84 / 50%);
    }

    .series-color-red .maMainMenuLinkHover__arrow {
        background-color: rgb(237 85 100 / 50%);
    }

    .series-color-blue .maMainMenuLinkHover__arrow {
        background-color: rgb(79 193 232 / 50%);
    }

    .series-color-purple .maMainMenuLinkHover__arrow {
        background-color: rgb(172 146 235 / 50%);
    }

    .series-color-green .maMainMenuLinkHover__arrow {
        background-color: rgb(160 213 104 / 50%);
    }

    .maCollection__content {
        grid-template-columns: repeat(4, 1fr);
    }

    // .maCollectionContent__link {
    //     width: 237px;
    //     height: 358px;
    // }

    .ma__collection:not(.ma__collectionPage) .maCollectionContent__link {
        width: 217px;
        height: 328px;
    }

    .collectionWrap {
        background-color: transparent;
    }

    .maCollection__content1 {
        background-color: $deep-blue;
    }

    .ma__top:after {
        background-color: #0000004d;
    }

    .maLogo__textWrap {
        background-color: rgb(4 18 45 / 80%);
    }

    .maLogo__imgContent {
        background-color: rgb(6 26 64);
    }

    .ma__collection:not(.ma__collectionPage) .maCollectionContent__link .maMainMenuLink__hover {
        width: 180px;
        height: 180px;
        left: 49.1%;
        top: 41.8%;
        padding: 0 10px;
        box-sizing: border-box;
        line-height: 120%;
    }

    .ma__collectionPage .maCollectionContent__link .maMainMenuLink__hover {
        width: 114px;
        height: 114px;
        top: 44%;
    }

    .ma__collectionPage .maMainMenuLinkHover__text {
        font-size: 14px;
    }

    .ma__collectionPage .maMainMenuLink__number {
        bottom: 11px;
        left: 15px;
        font-size: 12px;
    }

    .maDetail .maDetailContentMain__value {
        left: 40px;
        bottom: 30px;
        transform: initial;
    }

    .maMainMenuLink__hover {
        width: 132px;
        height: 132px;
        top: 42%;
    }

    @media(max-width: 1500px) {
        .ma__collection:not(.ma__collectionPage) .maCollectionContent__link {
            width: 210px;
            height: 317px
        }
    }

    @media(max-width: 1200px) {
        .ma__collection:not(.ma__collectionPage) .maCollectionContent__link {
            width: 175px;
            height: 264px;
        }

        .maMainMenuLink__number {
            bottom: 13px;
            left: 20px;
        }

        .ma__collection:not(.ma__collectionPage) .maCollectionContent__link .maMainMenuLink__hover {
            width: 140px;
            height: 140px;
        }
    }

    @media(max-width: 1024px) {
        .maCollection__content {
            grid-template-columns: repeat(3, 1fr);
        }

        .ma__collection:not(.ma__collectionPage) .maCollectionContent__link {
            width: 150px;
            height: 228px;
        }

        .maMainMenuLink__number {
            font-size: 13px;
        }

        .ma__collection:not(.ma__collectionPage) .maCollectionContent__link .maMainMenuLink__hover {
            width: 120px;
            height: 120px;
        }

        .maMainMenuLinkHover__text {
            font-size: 14px;
        }
    }

    @media(max-width: 768px) {
        .maTop__left:after {
            bottom: 8px;
        }

        .maMenuLink__span {
            top: -22px;
        }

        .maCollection__content {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media(max-width: 576px) {
        .maCollection__content {
            grid-template-columns: repeat(1, 1fr);
        }

        .ma__collectionPage .maCollectionContent__link .maMainMenuLink__hover {
            width: 84px;
            height: 84px;
            top: 42%;
        }

        .ma__collectionPage .maMainMenuLinkHover__text {
            font-size: 10px;
        }

        .ma__collectionPage .maMainMenuLinkHover__arrow {
            width: 35px;
            height: 35px;
            font-size: 20px;
        }
    }
}

.mainWrapper__content-wisdom {
    .maDetail__content {
        background-color: #061a40;
    }

    .maDetailContentMain__info {
        background-color: #04122d;
    }

    .maDetailContent__price {
        background-color: #04122d;
    }



}

@media(max-width: 576px) {
    .maDetail .maDetail__content {
        width: calc(100% - 10px);
        box-sizing: border-box;
        margin: 0 auto;
    }

    .maDetailContentPrice__row-green .maDetailContentPrice__value {
        background-color: #04122d;
    }

    .maDetailContentPrice__row-green .maDetailContentPrice__elem {
        background-color: #04122d;
    }
}