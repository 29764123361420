@use '@styles/variables.scss';

.address-card__wrap{
    display: flex;
    flex-direction: column;
    margin: 5px;
    // flex: 1;
    flex: 1 1 30%;

    .address-card{

        position: relative;

        background: variables.$colorNoGradient;
        background: variables.$colorGradient;

        -webkit-box-shadow: 0px 0px 11px 1px rgba(0,0,0,0.10); 
        box-shadow: 0px 0px 11px 1px rgba(0,0,0,0.10);
        padding: 30px 40px;
        border-radius: 10px;
        z-index: 1;

        .address-card__title{
            font-size: 28px;
            font-weight: bold;
            // color: #000;

            background-color: #e3a078;
            height: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 24px;
            position: relative;
            color: #ffffff;
            font-size: 18px;
            font-weight: bold;
            font-family: "Bebas Neue";
            text-shadow: 0.707px 0.707px 1px rgb(0 0 0 / 60%);
            margin-bottom: 10px;
        }

        .address-card__balance{
            background: #ffffff36;
            margin-top: 10px;
            font-size: 20px;
            font-weight: bold;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
        }

        &.address-card__GOS{
            background: #f7d6b6;
            // border: 1px solid #ffa000;
        }
        
        &.address-card__GICO{
            background: rgb(20 25 80);
            // border: 1px solid #ffa000;
            color: #fff !important;

            .address-card__title{
                background-image: -webkit-linear-gradient(45deg, rgb(215, 10, 132) 0%, rgb(81, 18, 127) 100%);
            }
        }
        
        &.address-card__DAO{
            background: #1ba600;
            // border: 1px solid #008000;
            color: #fff !important;

            .address-card__title{
                background-image: -webkit-linear-gradient(45deg, rgb(128, 0, 0) 0%, rgb(255, 0, 0) 100%);
            }
        }
        
        &.address-card__DAOA{
            background: #ffe670;
            // border: 1px solid #e5c04e;
            position: relative;

            .daoa__image{
                position: absolute;
                z-index: 10;
                bottom: -100px;
            }

            .address-card__title{
                background-image: -webkit-linear-gradient(45deg, rgb(255, 0, 0) 0%, rgb(128, 0, 0) 100%);
            }
        }

        &.address-card__video{
            overflow: hidden;

            .address-card__videoWrap{

                position: absolute;
                background: transparent;
                bottom: 0;
                right: 0;
                border-bottom-right-radius: 12px;
                overflow: hidden;

                .address-card__videoElement{            
                    mix-blend-mode: screen;
                    width: 190px;
                    height: 190px;
                    
                }
            }
        }

        .address-card__buttons{
            display: flex;
            align-items: center;
            justify-content: center;

            .address-card__buttonWrap{

                .address-card__button{
                    background: #ffffffe6;
                    border: none;
                    padding: 9px 30px;
                    border-radius: 5px;
                    color: #ff6f00;
                    cursor: pointer;
                }

                &:not(:last-child) {
                    margin-right: 10px;
                }
                
            }
        }

        
        .address-card__mt{
            margin-top: 20px;
        }

        .address-card__flex{
            display: flex;
            align-items: center;
            justify-content: space-between;        
        }

        .address-card__copy{
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .address-card__latestTitle{
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 15px;
            color: #000;
        }

        .address-card__transNotFound{
            margin-top: 20px;
            font-size: 13px;
            text-align: left;
        }

        

    }
}

.transactions__list{
    
    background: variables.$colorNoGradient;
    background: variables.$colorGradient;
    padding: 20px;
    border-radius: 10px;

    .transactions__list-row{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin-top: 10px;

        .transactions__list-from,
        .transactions__list-to{
            font-size: 14px;
        }

        .transactions__list-arrow{
            margin: 0 10px;
        }

        .transactions__list-amount{
            margin-left: 20px;
        }

    }
}

.level-active {
    border: 3px solid transparent;
    border-radius: 6px !important;
    border-image: linear-gradient(to bottom right, #026caa, #9c2ee5) 1;
}
.address-card__levelBox {
    font-size: 28px;
    font-weight: bold;
    background-color: #1e2d4c;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px !important;
    position: relative;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    font-family: "Bebas Neue";
    text-shadow: 0.707px 0.707px 1px rgb(0 0 0 / 60%);
    margin-bottom: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.gico-balance {
    font-size: 28px;
    font-weight: bold;
    // color: #000;

    background-color:#1b2336;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    position: relative;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    font-family: "Bebas Neue";
    text-shadow: 0.707px 0.707px 1px rgb(0 0 0 / 60%);
    margin-bottom: 10px;
}

.exchange__title{
    font-size: 28px;
    font-weight: bold;
    // color: #000;

    background-color:#1b2336;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    position: relative;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Bebas Neue";
    text-shadow: 0.707px 0.707px 1px rgb(0 0 0 / 60%);
    margin-bottom: 10px;
}

.converter {
    background-color: #212e48;
    margin-top: 20px;
    border-radius: 6px;
    padding: 30px 40px;
}
.converter__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}
.converterContent__row {
    color: #fff !important;
    background: rgb(255 255 255 / 10%) !important;
    border-radius: 20px !important;
    padding: 20px 20px 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.converterContent__left {
    display: flex;
    flex-direction: column;
}
.converterContent__label {
    font-size: 16px;
    font-weight: 500;
    text-shadow: 2px 1px 1px #000000;
    color: #fff;
}

.converterContent__input {
    margin-top: 5px;
    height: 50px;
    font-size: 34px;
    font-weight: bold;
    background: initial;
    border: initial;
    color: #fff;
}

.converterContent__input:focus {
    outline: none;
}

.converterContent__input::placeholder {
    opacity: 0.5;
    color: #fff;
  }

.converterContent__right {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.swapForm__logo {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #c5c5c5;
    padding: 5px;
    max-width: 180px;
    width: 100%;
    gap: 10px;
}
.swapForm__logo-dao {
    color: #ff0;
    background-color: #002d00;
    border-color: #ff0;
}
.swapFormLogo__iconWrap {
}
.swapFormLogo__icon {
    max-width: 40px;
}
.swapFormLogo__text {
    font-size: 14px;
    font-weight: bold;
}
.swapForm__balance {
    position: absolute;
    bottom: 10px;
    right: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-shadow: 2px 1px 1px #000000;
}

.swapForm__logo-dao-adv {
    background-color: #b40000;
    border-color: #ffff00;
    color: #ffff00;
}

.swap__changeBtn {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 3px solid #212e48;
    top: 50%;
    left: 50%;
    position: absolute;
    z-index: 1;
    transform: translate(-50%, -50%);
    background-color: #38435b;
    cursor: pointer;
    transition: 500ms all;
}

.swap__changeBtn svg path {
    fill: #fff;
}

.swap__changeBtn:focus {
    outline: none;
}

.swap__changeBtn:hover {
    background-color: #005281;
}

.swap__well {
    margin-top: 10px;
    border: 1px solid rgb(255 255 255 / 30%);
    border-radius: 10px;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.swapWell__text {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
}

.converter__btnWrap {
    max-width: 485px;
    width: 100%;
    margin: 10px auto 0;
}

.swap__informer {
    border-radius: 6px;
    margin-top: 30px;
    padding: 15px;
    border: 1px solid #646d7f;
    display: flex;
    align-items: center;
    gap: 30px;
}

.swapInformer__textWrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.swapInformer__text {
    color: #fff;
    font-weight: 500;
    line-height: 140%;
    font-size: 14px;
    font-style: italic;
}

.convert__btn:disabled {
    background-color: #005281!important;
    filter: grayscale(1);
    opacity: 0.5;
    cursor: no-drop;
}

@media(max-width: 1024px) {
    .converter {
        padding: 20px;
    }

    .converterContent__input {
        font-size: 28px;
    }
}

@media(max-width: 768px) {
    .converterContent__input {
        font-size: 19px;
    }

    .swapWell__text {
        font-size: 18px;
    }
}

@media(max-width: 576px) {
    .converterContent__row {
        flex-direction: column;
    }

    .converterContent__label {
        text-align: center;
    }

    .converterContent__input {
        text-align: center;
    }

    .converterContent__input {
        font-size: 16px;
    }

    .swap__changeBtn {
        position: relative;
        transform: initial;
        top: initial;
        left: initial;
        z-index: 1;
        margin: -30px auto;
    }

    .converterContent__row:first-of-type {
        padding-bottom: 60px;
    }

    .converterContent__row:last-of-type {
        padding-top: 30px;
    }

    .swapForm__balance {
        text-align: center;
        width: 100%;
        font-size: 12px;
        left: 0;
    }

    .converterContent__row:first-of-type .swapForm__balance {
        bottom: 34px;
        text-align: center;
        width: 100%;
        font-size: 12px;
    }

    .converterContent__row:last-of-type .swapForm__balance {
        bottom: 15px;
    }

    .swapWell__text {
        font-size: 11px;
    }

    .swap__well {
        height: 40px;
    }

    .swapFormLogo__icon {
        max-width: 25px;
    }

    .swapInformer__text {
        font-size: 12px;
    }

    .swap__informer {
        margin-top: 20px;
    }

    .swapForm__logo {
        justify-content: center;
    }
}

@media (max-width: 475px) {
    .exchange__title {
        text-align: center;
        font-size: 13px;
    }
}
