@use 'volumeModule';

@mixin info-container($borderRadius: 0 1rem 0 0) {
    padding: 0.7rem 0;
    border-radius: $borderRadius;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.card_container {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-size: 1.4rem;

    @media only screen and (max-width: volumeModule.$lg) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: volumeModule.$md) {
        font-size: 1.2rem;
    }

    @media only screen and (max-width: volumeModule.$sm) {
        font-size: 1.1rem;
        height: 550px;
        flex-direction: column;
    }

    @media only screen and (max-width: volumeModule.$xs) {
        font-size: 1rem;
        height: 500px;
    }

    @media only screen and (max-width: volumeModule.$xxs) {
        font-size: 1rem;
        height: 450px;
    }

    .card_image {
        height: 100%;
        object-fit: contain;

        @media only screen and (max-width: volumeModule.$sm) {
            height: 300px;
            z-index: 2;
        }

        @media only screen and (max-width: volumeModule.$xs) {
            height: 250px;
        }

        @media only screen and (max-width: volumeModule.$xxs) {
            height: 200px;
        }
    }

    .content_wrapper {
        width: 100%;
        height: 100%;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        box-sizing: border-box;
        font-weight: 500;

        @media only screen and (max-width: volumeModule.$sm) {
            position: relative;
            top: -70px;
            z-index: 1;
        }

        @media only screen and (max-width: volumeModule.$xs) {
            position: relative;
            top: -50px;
            z-index: 1;
        }

        .info_wrapper {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-around;
            box-sizing: border-box;
            flex-grow: 1;
            @include info-container;

            @media only screen and (max-width: volumeModule.$lg) {
                flex-direction: column;
                gap: 0.5rem 0;
            }

            @media only screen and (max-width: volumeModule.$sm) {
                height: 130px;
                justify-content: flex-end;
                gap: 1rem 0;
                @include info-container($borderRadius: 1rem 1rem 0 0);
            }

            @media only screen and (max-width: volumeModule.$xs) {
                height: 100px;
            }

            .info_block {
                display: flex;
                width: 50%;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 0 1rem;

                @media only screen and (max-width: volumeModule.$lg) {
                    width: 100%;
                }

                .ellipsis_wrapper {
                    height: 0;
                    align-self: flex-end;
                    padding: 0 0.5rem;
                    flex-grow: 1;

                    .ellipsis {
                        margin: 0;
                        width: 100%;
                        height: 0;
                        border: dashed 0.1rem white;
                    }
                }
            }

            .divider {
                height: 100%;
                margin: 0;
                border: solid 0.1rem white;
                border-radius: 0.5rem;

                @media only screen and (max-width: volumeModule.$lg) {
                    display: none;
                }
            }
        }

        .wrapper_divider {
            flex-grow: 1;

            @media only screen and (max-width: volumeModule.$sm) {
                height: 0.3rem;
            }
        }

        .data_wrapper {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            flex-grow: 10;
            @include info-container($borderRadius: 0 0 1rem 0);
            padding: 0.7rem 1rem;

            @media only screen and (max-width: volumeModule.$sm) {
                @include info-container($borderRadius: 0 0 1rem 1rem);
                text-align: center;
                gap: 2rem;
                padding: 0.7rem 1rem;
            }

            .data_link {
                all: unset;
                color: volumeModule.$faded_yellow;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .button_wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        @media only screen and (max-width: volumeModule.$sm) {
            justify-content: center;
        }

        .cardButton {
            text-transform: uppercase;
            font-size: inherit;
            border-radius: 1.5rem;
            padding: 0.5rem 2rem;
            font-weight: bold;
            color: white;
            letter-spacing: 0.1rem;
            background-color: volumeModule.$soft_red;
            text-shadow: 0 0 0.2rem black;
            cursor: pointer;
            border: solid 0.1rem white;
            box-shadow: 0 0 0.5rem white;
            outline-style: inset;
            outline-width: 0.1rem;
            outline-color: volumeModule.$soft_red;
            outline-offset: -0.4rem;
            transition: all 0.5s;

            @media (hover: hover) {
                &:hover {
                    box-shadow: 0 0 1.5rem white;
                    outline-color: white;
                    text-shadow: 0 0 0.1rem black;
                }
            }
        }
    }
}